import React from "react";
import styles from "./index.module.scss";
import PropTypes from "prop-types";

const type = "checkbox";
const RadioButton = ({ id, onChange, checked, label, className, disabled }) => {
  const disabledClassName = disabled ? styles.disabled : "";

  return (
    <label className={`${styles.container} ${className} ${disabledClassName}`}>
      {label}
      <input
        id={id}
        className={styles.checkbox}
        type={type}
        disabled={disabled}
        onChange={(event) => onChange(event.target.checked)}
        checked={checked}
      />
      <span className={styles.checkMark} />
    </label>
  );
};

RadioButton.defaultProps = {
  id: null,
  className: "",
  onChange: () => {},
  checked: false,
  label: null,
  disabled: false,
};
RadioButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
};

export default RadioButton;
