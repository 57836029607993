import React, { FC, useMemo } from "react";
import { branch } from "baobab-react/higher-order";

import TEST_STRUCT from "../../../../store/struct/entities/test";
import { CALCULATED_LINE_CATEGORY_STRUCT } from "../../../../store/struct/entities/vocabulary";

import styles from "./index.module.scss";
import Form, { TYPES as FORM_TYPES } from "../../../../components/form";

interface TestSettingsProps {
  formRef: React.LegacyRef<any>;
  errorValidationDuration: boolean;
  errorValidationGoal: boolean;
  errorValidationStartDate: boolean;
  errorValidationEndDate: boolean;
  errorValidationCategoryCount: any;
  selectedQuestionsLineCategoriesCount: any;
  settingFormData: any;
  settingsIsDisabled: boolean;
  validateCategoryCount: (value: number, id: string, count: number) => void;
  validateDuration: (value: number) => void;
  validateStartDate: (datetime: any) => void;
  validateEndDate: (datetime: any) => void;
  validateGoal: (value: number) => void;
  handleSettingFormData: (state: any) => void;
}

const TestSettings: FC<TestSettingsProps> = ({
  formRef,
  errorValidationDuration,
  errorValidationGoal,
  errorValidationStartDate,
  errorValidationEndDate,
  errorValidationCategoryCount,
  selectedQuestionsLineCategoriesCount,
  settingFormData,
  settingsIsDisabled,
  validateCategoryCount,
  validateDuration,
  validateStartDate,
  validateEndDate,
  validateGoal,
  handleSettingFormData,
}) => {
  const formConfig = useMemo(() => {
    const config = [
      {
        id: TEST_STRUCT.TITLE,
        title: "Заголовок теста",
        type: FORM_TYPES.INPUT,
        initialValue: settingFormData[TEST_STRUCT.TITLE],
        className: styles.titleInput,
        classNameLabel: `${styles.titleText} ${styles.marginRight}`,
        classNameLi: styles.rowContainer,
        disabled: settingsIsDisabled,
      },
      {
        id: TEST_STRUCT.DURATION,
        title: "Время на тест",
        titleAfter: "мин",
        type: FORM_TYPES.INPUT,
        initialValue: settingFormData[TEST_STRUCT.DURATION],
        attrs: {
          type: "number",
          min: 0,
        },
        classNameLabelAfter: `${styles.titleText} ${styles.marginLeft}  ${
          errorValidationDuration ? styles.error : ""
        }`,
        className: `${
          errorValidationDuration ? styles.errorInputContainer : ""
        }`,
        classNameLabel: `${styles.titleText} ${styles.marginRight} ${
          errorValidationDuration ? styles.error : ""
        }`,
        classNameLi: styles.rowContainer,
        validationFunc: validateDuration,
        disabled: settingsIsDisabled,
      },
      {
        id: TEST_STRUCT.START_DATE,
        title: "Дата и время открытия теста",
        type: FORM_TYPES.DATETIME,
        initialValue: settingFormData[TEST_STRUCT.START_DATE],
        attrs: {
          type: "number",
          min: 0,
        },
        className: `${
          errorValidationStartDate ? styles.errorInputContainer : ""
        }`,
        classNameLabel: `${styles.titleText} ${styles.marginRight} ${
          errorValidationStartDate ? styles.error : ""
        }`,
        classNameLi: styles.rowContainer,
        validationFunc: validateStartDate,
        disabled: settingsIsDisabled,
      },
      {
        id: TEST_STRUCT.END_DATE,
        title: "Дата и время закрытия теста",
        type: FORM_TYPES.DATETIME,
        initialValue: settingFormData[TEST_STRUCT.END_DATE],
        attrs: {
          type: "number",
          min: 0,
        },
        className: `${
          errorValidationEndDate ? styles.errorInputContainer : ""
        }`,
        classNameLabel: `${styles.titleText} ${styles.marginRight} ${
          errorValidationEndDate ? styles.error : ""
        }`,
        classNameLi: styles.rowContainer,
        validationFunc: validateEndDate,
        disabled: settingsIsDisabled,
      },
      {
        id: TEST_STRUCT.GOAL_PERCENT,
        title: "Проходной балл",
        titleAfter: "%",
        type: FORM_TYPES.INPUT,
        initialValue: settingFormData[TEST_STRUCT.GOAL_PERCENT],
        attrs: {
          type: "number",
          min: 0,
          max: 100,
        },
        classNameLabelAfter: `${styles.titleText} ${styles.marginLeft}  ${
          errorValidationGoal ? styles.error : ""
        }`,
        className: `${errorValidationGoal ? styles.errorInputContainer : ""}`,
        classNameLabel: `${styles.titleText} ${styles.marginRight}  ${
          errorValidationGoal ? styles.error : ""
        }`,
        classNameLi: styles.rowContainer,
        validationFunc: validateGoal,
        disabled: settingsIsDisabled,
      },
    ];
    selectedQuestionsLineCategoriesCount.forEach(
      (category: any, index: number) => {
        config.push({
          id: `${TEST_STRUCT.QUESTIONS_COUNT_IN_CATEGORIES}_${
            category[CALCULATED_LINE_CATEGORY_STRUCT.TITLE]
          }`,
          title: `Вопросы категории "${
            category[CALCULATED_LINE_CATEGORY_STRUCT.TITLE]
          }"`,
          titleAfter: `из ${category[CALCULATED_LINE_CATEGORY_STRUCT.COUNT]}`,
          type: FORM_TYPES.INPUT,
          initialValue:
            settingFormData[
              `${TEST_STRUCT.QUESTIONS_COUNT_IN_CATEGORIES}_${
                category[CALCULATED_LINE_CATEGORY_STRUCT.TITLE]
              }`
            ],
          attrs: {
            type: "number",
            min: 0,
            max: category[CALCULATED_LINE_CATEGORY_STRUCT.COUNT],
          },
          classNameLabelAfter: `${styles.titleText} ${styles.marginLeft}  ${
            errorValidationCategoryCount[
              `${category[CALCULATED_LINE_CATEGORY_STRUCT.LINE_ID]}-${
                category[CALCULATED_LINE_CATEGORY_STRUCT.CATEGORY_ID]
              }`
            ]
              ? styles.error
              : ""
          }`,
          className: `${
            errorValidationCategoryCount[
              `${category[CALCULATED_LINE_CATEGORY_STRUCT.LINE_ID]}-${
                category[CALCULATED_LINE_CATEGORY_STRUCT.CATEGORY_ID]
              }`
            ]
              ? styles.errorInputContainer
              : ""
          }`,
          classNameLabel: `${styles.titleText} ${styles.marginRight}  ${
            errorValidationCategoryCount[
              `${category[CALCULATED_LINE_CATEGORY_STRUCT.LINE_ID]}-${
                category[CALCULATED_LINE_CATEGORY_STRUCT.CATEGORY_ID]
              }`
            ]
              ? styles.error
              : ""
          }`,
          classNameLi: `${styles.rowContainer} ${styles.spaceBetweenRow} ${
            index === 0 ? styles.marginTop : ""
          }`,
          validationFunc: (value: number) =>
            validateCategoryCount(
              value,
              `${category[CALCULATED_LINE_CATEGORY_STRUCT.LINE_ID]}-${
                category[CALCULATED_LINE_CATEGORY_STRUCT.CATEGORY_ID]
              }`,
              category[CALCULATED_LINE_CATEGORY_STRUCT.COUNT]
            ),
          disabled: settingsIsDisabled,
        });
      }
    );
    return config;
  }, [
    errorValidationCategoryCount,
    errorValidationDuration,
    errorValidationEndDate,
    errorValidationGoal,
    errorValidationStartDate,
    selectedQuestionsLineCategoriesCount,
    settingFormData,
    settingsIsDisabled,
    validateCategoryCount,
    validateDuration,
    validateEndDate,
    validateGoal,
    validateStartDate,
  ]);

  const onChangeForm = (state: any) => {
    handleSettingFormData(state);
  };

  return (
    <>
      {/* @ts-ignore */}
      <Form ref={formRef} config={formConfig} onChange={onChangeForm} />
    </>
  );
};

export default branch({}, TestSettings);
