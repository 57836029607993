import React, { FC, useCallback, useEffect } from "react";
import { branch } from "baobab-react/higher-order";

// @ts-ignore
import scssVars from "../styles/_vars.scss";

import styles from "./index.module.scss";
import { getSavedCurrentUserId } from "../store/storage";
import { logout, sendPin } from "../store/struct/app/actions";
import { ROLES } from "../store/struct/entities/role";
import Tabs from "../containers/tabs";
import { Loader } from "../components/loader";
import AdminContainer from "../containers/admin";
import AgentContainer from "../containers/agent";
import PinInput from "../components/pin-input";
import ConfirmDialog from "../containers/confirm-dialog";
import {
  authStatusSelector,
  pinSelector,
  pinSendingStatusSelector,
  roleSelector,
  userIdSelector,
} from "../store/struct/app/selectors";

const containers = {
  [ROLES.ADMIN]: AdminContainer,
  [ROLES.AGENT]: AgentContainer,
};

export const checkUserStorage = { CHECK_CURRENT_LOGIN: "USER_ID" };

interface AppProps {
  role: any;
  userId: any;
  dispatch: any;
  pinSending: any;
  authStatus: any;
}

const App: FC<AppProps> = ({
  role,
  userId,
  dispatch,
  pinSending,
  authStatus,
  // managerRole,
}) => {
  const logOut = useCallback(() => {
    dispatch(logout);
  }, [dispatch]);

  useEffect(() => {
    const handler = () => {
      const savedUserId = getSavedCurrentUserId();
      if (!savedUserId || (userId && savedUserId && userId !== savedUserId)) {
        // logOut();
      }
    };
    window.addEventListener("focus", handler);

    return () => {
      window.removeEventListener("focus", handler);
    };
  }, [userId, logOut]);

  const pinSubmit = useCallback(
    (value: string) => {
      dispatch(sendPin, value);
    },
    [dispatch]
  );
  const CurrentContainer = role ? containers[role] : null;
  return (
    <section className={`${scssVars.lightTheme} ${styles.container}`}>
      {!CurrentContainer && authStatus && (
        <>
          <Tabs header="" tabs={[]} />
          <Loader />
        </>
      )}
      {!CurrentContainer && !authStatus && (
        <PinInput disabled={pinSending} onSubmit={pinSubmit} />
      )}
      {CurrentContainer && <CurrentContainer />}
      <ConfirmDialog />
    </section>
  );
};

export default branch(
  {
    pinSending: pinSendingStatusSelector(),
    pin: pinSelector(),
    role: roleSelector(),
    userId: userIdSelector(),
    authStatus: authStatusSelector(),
    entities: "entities",
  },
  App
);
