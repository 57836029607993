import _ from "lodash";
import find from "lodash.find";

const CATEGORIES_ORDER = [
  { index: 0, categoryTitle: "Медицинские знания" },
  { index: 1, categoryTitle: "Маркетинговая стратегия" },
  { index: 2, categoryTitle: "Market Access" },
];

export const getById = (array: any, id: number) => {
  if (!array || !array.length) {
    return null;
  }

  return find(array, { id }) || null;
};

export const toDecimalPlaces = (value: number, decimalPlaces: number) => {
  const k = Math.pow(10, decimalPlaces);

  return Math.round(value * k) / k;
};

export const getUniqueParam = (
  array: any,
  paramId: string,
  paramTitle: string
) => {
  return array
    .map((value: any) => ({
      id: value[paramId],
      title: value[paramTitle],
    }))
    .filter((object: any, index: number, self: any) => {
      return (
        index ===
        self.findIndex(
          (obj: any) => JSON.stringify(obj) === JSON.stringify(object)
        )
      );
    });
};

export const getRandomElementsByLineCategory = (
  items: any,
  countsByLineCategory: any,
  categories: any
) => {
  // Группируем элементы по линии-категории
  const linesCategories = items.reduce((acc: any, item: any) => {
    if (!acc[`${item.line_id}-${item.category_id}`]) {
      acc[`${item.line_id}-${item.category_id}`] = [];
    }
    acc[`${item.line_id}-${item.category_id}`].push(item);
    return acc;
  }, {});

  // Выбираем случайные элементы для каждой линии-категории
  const result: any = {};
  for (let lineCategory in linesCategories) {
    let shuffled = _.shuffle(linesCategories[lineCategory]);
    let count = countsByLineCategory[lineCategory] || 0; // Количество элементов для данной линии-категории или 0
    // @ts-ignore
    result[lineCategory] = shuffled.slice(0, count);
  }

  // Преобразуем CATEGORIES_ORDER в словарь для быстрого доступа к индексам
  const categoryOrderMap = CATEGORIES_ORDER.reduce(
    (acc: any, category: any) => {
      acc[category.categoryTitle] = category.index;
      return acc;
    },
    {}
  );

  // Преобразуем массив categories в объект для быстрого доступа по id
  const categoryMap = categories.reduce((acc: any, category: any) => {
    acc[category.id] = category.title;
    return acc;
  }, {});

  // Создаем пустой массив для результирующего порядка
  let arrayResult: any = [];

  // Функция для сортировки ключей
  const sortKeys = (keys: any) => {
    return keys.sort((a: any, b: any) => {
      const [lineA, catA] = a.split("-").map(Number);
      const [lineB, catB] = b.split("-").map(Number);

      if (lineA === lineB) {
        const categoryTitleA = categoryMap[catA];
        const categoryTitleB = categoryMap[catB];
        const indexA = categoryOrderMap[categoryTitleA];
        const indexB = categoryOrderMap[categoryTitleB];
        return indexA - indexB;
      }

      return lineA - lineB;
    });
  };

  // Сортируем ключи lineId
  const sortedLineIds = sortKeys(Object.keys(result));

  // Пройдемся по всем отсортированным ключам и добавим элементы в нужном порядке
  sortedLineIds.forEach((key: any) => {
    arrayResult.push(...result[key]);
  });

  const formattedResult = arrayResult
    .reduce((acc: any, current: any) => {
      acc.push(current);
      return acc;
    }, [])
    .map((currentValue: any) => currentValue.id);

  return formattedResult;
};

export const filterByIds = (items: any, ids: any) => {
  const idSet = new Set(ids);
  return items.filter((item: any) => idSet.has(item.id));
};

export const countElements = (arr: any) => {
  const count: any = {};
  for (let item of arr) {
    count[item] = (count[item] || 0) + 1;
  }
  return count;
};

export const haveSameElementsWithDuplicates = (arr1: any, arr2: any) => {
  if (arr1.length !== arr2.length) {
    return false; // Быстрая проверка длины
  }

  const count1 = countElements(arr1);
  const count2 = countElements(arr2);

  for (let key in count1) {
    if (count1[key] !== count2[key]) {
      return false; // Проверка количества каждого элемента
    }
  }

  return true;
};

export const arraysAreEqualWithRightOrder = <T>(
  arr1: T[],
  arr2: T[]
): boolean =>
  arr1.length === arr2.length &&
  arr1.every((value, index) => value === arr2[index]);
