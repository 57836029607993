import React, { FC, useState } from "react";
import DateTimePicker from "react-datetime-picker";

import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

import styles from "./index.module.scss";

interface DateTimeProps {
  attrs: any;
  value: any;
  onChange: any;
  className: any;
  disabled: boolean;
}

const DateTime: FC<DateTimeProps> = ({
  attrs,
  value,
  onChange,
  className,
  disabled,
}) => {
  return (
    <div className={className}>
      <DateTimePicker
        className={styles.dateInput}
        onChange={onChange}
        value={value}
        disabled={disabled}
        calendarIcon={null}
        shouldOpenWidgets={({ reason, widget }) => {
          return widget === "calendar";
        }}
        {...attrs}
      />
    </div>
  );
};

export default DateTime;
