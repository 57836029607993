import React from "react";
import Tabs from "../tabs";
import { ICONS } from "../../components/Icons";
import UserInfo from "./userInfo";
import Tests from "./tests";

import styles from "./index.module.scss";

const tabs = [
  {
    title: "Тесты",
    content: Tests,
    icon: ICONS.MeetIcon,
  },
];

const AgentContainer = (props: any) => {
  return (
    <>
      <div className={styles.content}>
        <Tabs tabs={tabs} {...props} header={<UserInfo {...props} />} />
      </div>
    </>
  );
};

export default AgentContainer;
