import React, {
  useReducer,
  useImperativeHandle,
  useEffect,
  useCallback,
  forwardRef,
} from "react";
import PropTypes from "prop-types";

import Buttons from "./buttons";
import Select from "./select";
import Checkboxes from "./checkboxes";
import Input from "./input";
import Link from "./link";
import DateTime from "./datetime";

import styles from "./index.module.scss";

export const TYPES = {
  BUTTONS: "buttons",
  SELECT: "select",
  CHECKBOXES: "checkboxes",
  INPUT: "input",
  TEXTAREA: "textarea",
  LINK: "a",
  DATETIME: "datetime",
};

export const PARAMS = {
  ID: "id",
  TITLE: "title",
  TITLE_AFTER: "titleAfter",
  TITLE_UNDER: "titleUnder",
  TYPE: "type",
  SUBTYPE: "subtype",
  OPTIONS: "options",
  INITIAL_VALUE: "initialValue",
  VALUE: "value",
  CLASSNAME: "className",
  CLASSNAMELI: "classNameLi",
  CLASSNAME_LABEL: "classNameLabel",
  CLASSNAME_LABEL_AFTER: "classNameLabelAfter",
  CLASSNAME_LABEL_UNDER: "classNameLabelUnder",
  READONLY: "readonly",
  DISABLED: "disabled",
  ATTRS: "attrs",
  HIDDEN: "hidden",
  VALIDATION_FUNC: "validationFunc",
  PLACEHOLDER: "placeholder",
  SELECTABLE_PLACEHOLDER: "selectablePlaceholder",
  ON_RESET: "onReset",
};

const FIELDS = {
  [TYPES.BUTTONS]: {
    component: Buttons,
    className: styles.buttons,
    stateless: true,
  },
  [TYPES.SELECT]: {
    component: Select,
    className: styles.select,
    empty: "",
  },
  [TYPES.CHECKBOXES]: {
    component: Checkboxes,
    className: styles.checkboxes,
    empty: [],
  },
  [TYPES.INPUT]: {
    component: Input,
    className: styles.input,
    empty: "",
  },
  [TYPES.LINK]: {
    component: Link,
    className: styles.link,
    empty: ["", ""],
  },
  [TYPES.DATETIME]: {
    component: DateTime,
    className: styles.datetime,
    empty: "",
  },
};

const reducer = (state, action) => {
  const newState = {
    ...state,
    [action.id]: action.value,
  };

  action.config.forEach((field) => {
    if (typeof field[PARAMS.VALUE] === "function") {
      newState[field[PARAMS.ID]] = field[PARAMS.VALUE](newState);
    }
  });
  return newState;
};

const FormFactory = forwardRef(
  ({ config, className, onClick, onChange }, ref) => {
    const [state, dispatch] = useReducer(
      reducer,
      config.reduce((acc, field) => {
        if (!FIELDS[field[PARAMS.TYPE]].stateless && !field[PARAMS.READONLY]) {
          acc[field[PARAMS.ID]] =
            PARAMS.INITIAL_VALUE in field
              ? field[PARAMS.INITIAL_VALUE]
              : FIELDS[field[PARAMS.TYPE].empty] || "";
        }

        return acc;
      }, {})
    );
    const calcValue = useCallback(
      (field) => {
        if (field[PARAMS.READONLY]) {
          return field[PARAMS.INITIAL_VALUE];
        }
        if (
          PARAMS.VALUE in field &&
          typeof field[PARAMS.VALUE] !== "function"
        ) {
          return field[PARAMS.VALUE];
        }
        return state[field[PARAMS.ID]];
      },
      [state]
    );
    useEffect(() => {
      onChange(state);
    }, [onChange, state]);

    useImperativeHandle(ref, () => ({
      getState: () => state,
      setState: (id, value) => {
        dispatch({ id, value, config });
      },
    }));

    return (
      <ul className={`${styles.fieldList} ${className}`}>
        {config.map((field) => {
          const Component = FIELDS[field[PARAMS.TYPE]].component;

          return (
            <li
              key={field[PARAMS.ID]}
              className={`${styles.field} ${field[PARAMS.CLASSNAMELI] || ""}`}
            >
              {field[PARAMS.TITLE] && (
                <div
                  className={`${styles.label} ${
                    field[PARAMS.CLASSNAME_LABEL || ""]
                  }`}
                >
                  {field[PARAMS.TITLE]}
                </div>
              )}
              <div
                className={`${styles.component} ${
                  field[PARAMS.HIDDEN] ? styles.hidden : ""
                }`}
              >
                <Component
                  className={`${FIELDS[field[PARAMS.TYPE]].className} ${
                    field[PARAMS.CLASSNAME] || ""
                  }`}
                  attrs={field[PARAMS.ATTRS]}
                  options={field[PARAMS.OPTIONS]}
                  subtype={field[PARAMS.SUBTYPE]}
                  readonly={field[PARAMS.READONLY]}
                  disabled={field[PARAMS.DISABLED]}
                  placeholder={field[PARAMS.PLACEHOLDER]}
                  selectablePlaceholder={field[PARAMS.SELECTABLE_PLACEHOLDER]}
                  onReset={field[PARAMS.ON_RESET]}
                  value={calcValue(field)}
                  onClick={(subId) => onClick(field[PARAMS.ID], subId, state)}
                  onChange={(value) => {
                    if (field[PARAMS.VALIDATION_FUNC]) {
                      field[PARAMS.VALIDATION_FUNC](value);
                    }
                    dispatch({ id: field[PARAMS.ID], value, config });
                  }}
                />
                {field[PARAMS.TITLE_AFTER] && (
                  <div
                    className={`${styles.label} ${
                      field[PARAMS.CLASSNAME_LABEL_AFTER || ""]
                    }`}
                  >
                    {field[PARAMS.TITLE_AFTER]}
                  </div>
                )}
              </div>
              {field[PARAMS.TITLE_UNDER] && (
                <div
                  className={`${styles.caption} ${
                    field[PARAMS.CLASSNAME_LABEL_UNDER || ""]
                  }`}
                >
                  {field[PARAMS.TITLE_UNDER]}
                </div>
              )}
            </li>
          );
        })}
      </ul>
    );
  }
);

export default FormFactory;

FormFactory.propTypes = {
  config: PropTypes.array.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
FormFactory.defaultProps = {
  disabled: false,
  className: "",
  onChange: () => null,
  onClick: () => null,
};
