import { getCookie } from "../../../utils/cookie";

const STRUCT = {
  PIN: "pin",
  ROLE: "role",
  PIN_SENDING: "pin_sending",
  CONNECTED: "connected",
  AUTHORIZED: "authorized",
  USER_ID: "user_id",
  CONFIRM_WINDOW: "confirm_window",
  CONFIRM_RESULT: "confirm_result",
};

export const CONFIRM_RESULTS = {
  APPROVE: 1,
  CANCEL: 0,
  IDLE: -1,
};

export default STRUCT;

export const AUTHORIZED = "novartis_authorized";

export const initialState = () => ({
  [STRUCT.PIN]: "",
  [STRUCT.ROLE]: 0,
  [STRUCT.PIN_SENDING]: false,
  [STRUCT.CONNECTED]: false,
  [STRUCT.AUTHORIZED]: getCookie(AUTHORIZED) === "true",
  [STRUCT.USER_ID]: null,
  [STRUCT.CONFIRM_WINDOW]: false,
  [STRUCT.CONFIRM_RESULT]: CONFIRM_RESULTS.IDLE,
});
