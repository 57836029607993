const STRUCT = {
  ID: "id",
  TITLE: "title",
  CREATED_AT: "created_at",
  CATEGORY_ID: "category_id",
  CATEGORY: "category",
  LINE_ID: "line_id",
  LINE: "line",
  DIRECTION_ID: "direction_id",
  DIRECTION: "direction",
  TYPE_ID: "type_id",
  ELEMENTS: "elements",
  ANSWERS: "answers",
  CORRECT_ANSWERS: "correct_answers",
  IS_DELETE_AVAILABLE: "is_delete_available",
  IS_HIDED: "is_hided",
};

export const QUESTION_TYPES_ID = {
  ONE_OF_MANY: 1,
  MANY_OF_MANY: 2,
  MATCHING: 3,
};

export const QUESTION_TYPES_NAMES = {
  ONE_OF_MANY: "Выбор одного из множества",
  MANY_OF_MANY: "Выбор многих из множества",
  MATCHING: "Соотнесение элементов",
};

export const QUESTION_TYPE_TITLE = {
  1: "Выберите один вариант ответа",
  2: "Выберите несколько вариантов ответа",
  3: "Выберите соответствие для каждого элемента",
};

export default STRUCT;
