import { createRemote, updateRemote } from "../atomic";
import ENTITIES from "..";
import { execAfterUserConfirmation } from "../../app/actions";

export function createTestResult(store, data) {
  execAfterUserConfirmation(store, () => {
    createRemote(store, ENTITIES.TESTS_RESULTS, data);
  });
}

export function updateTestResult(store, data, functionAfter) {
  execAfterUserConfirmation(store, () => {
    updateRemote(store, ENTITIES.TESTS_RESULTS, data);
    functionAfter();
  });
}

export function updateTestResultWithoutConfirmation(store, data) {
  updateRemote(store, ENTITIES.TESTS_RESULTS, data);
}
