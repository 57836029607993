import FileSaver from "file-saver";

/**
 * Safe byteArray as excel file
 * @param {ArrayBuffer} arrayBuffer
 */
export const saveExcelFile = (arrayBuffer) => {
  const blob = new Blob([arrayBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  FileSaver.saveAs(blob, "test-results.xlsx");
};
