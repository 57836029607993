const STRUCT = {
  ID: "id",
  TITLE: "title",
};

export const CALCULATED_LINE_CATEGORY_STRUCT = {
  LINE_ID: "lineId",
  CATEGORY_ID: "categoryId",
  TITLE: "title",
  COUNT: "count",
};

export const NA_CONST = "N/A";

export default STRUCT;
