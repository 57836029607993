import { createRemote, removeRemote, updateRemote } from "../atomic";
import { execAfterUserConfirmation } from "../../app/actions";
import ENTITIES from "..";

export function createTest(store, data) {
  createRemote(store, ENTITIES.TESTS, data);
}

export function updateTest(store, data) {
  updateRemote(store, ENTITIES.TESTS, data);
}

export function deleteTest(store, data, functionAfter) {
  execAfterUserConfirmation(store, () => {
    removeRemote(store, ENTITIES.TESTS, data);
    functionAfter();
  });
}
