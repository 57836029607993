import "./polyfills";
import React from "react";
import ReactDOM from "react-dom/client";
// import { render } from "react-dom";
import { root as baobabRoot } from "baobab-react/higher-order";

import App from "./App";
import store from "./store";

import "./styles/index.scss";
import reportWebVitals from "./reportWebVitals";

const RootedApp = baobabRoot(store, App);

// render(<RootedApp />, document.getElementById("root"));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <RootedApp />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
