const STRUCT = {
  ID: "id",
  TITLE: "title",
  CREATED_AT: "created_at",
  DATE: "date",
  DURATION: "duration",
  GOAL_PERCENT: "goal_percent",
  QUESTIONS: "questions",
  USERS: "users",
  START_DATE: "start_date",
  END_DATE: "end_date",
  QUESTIONS_COUNT_IN_CATEGORIES: "qestions_count_in_categories",
};

export const TEST_STATUSES = {
  SAVED: "saved",
  IN_PROGRESS: "in_progress",
  DONE: "done",
};

export default STRUCT;
