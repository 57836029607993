const STRUCT = {
  ID: "id",
  REPRESENTATIVE: "representative",
  REPRESENTATIVE_ID: "representative_id",
  TEST: "test",
  TEST_ID: "test_id",
  STATUS: "status",
  ANSWERS: "answers",
  ACTUAL_START: "actual_start",
  ACTUAL_END: "actual_end",
  USER_QUESTIONS: "user_questions",
  TEST_PERCENT: "test_percent",
  ANSWERED_QUESTIONS: "answered_questions",
  RESULT_POINTS: "result_points",
};

export default STRUCT;
