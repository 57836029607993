import React from "react";
import { branch } from "baobab-react/higher-order";
import { ICONS } from "../../components/Icons";

import Tabs from "../tabs";
import Users from "./users";
import Questions from "./questions";
import Tests from "./tests";

import styles from "./index.module.scss";

const tabs = [
  {
    title: "Пользователи",
    content: Users,
    icon: ICONS.PersonIcon,
  },
  {
    title: "Вопросы",
    content: Questions,
    icon: ICONS.UnionIcon,
  },
  {
    title: "Тесты",
    content: Tests,
    icon: ICONS.MeetIcon,
  },
];

const AdminContainer = (props) => {
  return (
    <Tabs
      tabs={tabs}
      {...props}
      header={<div className={styles.headerUserInfo}>{"Администратор"}</div>}
    />
  );
};

export default branch({}, AdminContainer);
