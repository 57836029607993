import React, { FC, useCallback, useMemo } from "react";

import QUESTION_STRUCT, {
  QUESTION_TYPES_ID,
  QUESTION_TYPE_TITLE,
} from "../../../../store/struct/entities/question";
import VOCABULARY_STRUCT from "../../../../store/struct/entities/vocabulary";

import styles from "./index.module.scss";
import Checkbox from "../../../../components/checkbox";
import RadioButton from "../../../../components/radio-button";
import Select from "../../../../components/form/select";

interface TestDialogQuestionProps {
  currQuestion: any;
  testResults: any;
  isTestEnded: boolean;
  onQuestionAnswerChange: (
    questionId: number,
    questionType: number,
    value: any,
    element?: string
  ) => void;
  onSelectableQuestionAnswerReset: (
    questionId: number,
    element: string
  ) => void;
}

const TestDialogQuestion: FC<TestDialogQuestionProps> = ({
  currQuestion,
  testResults,
  isTestEnded,
  onQuestionAnswerChange,
  onSelectableQuestionAnswerReset,
}) => {
  const currentQuestionResults = useMemo(() => {
    return testResults[currQuestion[QUESTION_STRUCT.ID]] ?? [];
  }, [currQuestion, testResults]);

  const isAnswerSelected = useCallback(
    (answer: string) => {
      const answerIdx = currentQuestionResults.findIndex(
        (resultAnswer: string) => resultAnswer === answer
      );
      return answerIdx > -1;
    },
    [currentQuestionResults]
  );

  const selectedAnswerValue = useCallback(
    (index: number, answersOptions: any) => {
      const answerOption = answersOptions.find(
        (option: any) =>
          option[VOCABULARY_STRUCT.TITLE] === currentQuestionResults[index]
      );

      return answerOption ? answerOption[VOCABULARY_STRUCT.ID] : null;
    },
    [currentQuestionResults]
  );

  const answersComponent = useCallback(() => {
    const currQuestionType = currQuestion[QUESTION_STRUCT.TYPE_ID];
    const answers = JSON.parse(currQuestion[QUESTION_STRUCT.ANSWERS]);
    switch (currQuestionType) {
      case QUESTION_TYPES_ID.MANY_OF_MANY:
        return (
          <>
            {answers.map((answer: string, index: number) => {
              const answerCorrectIdx = JSON.parse(
                currQuestion[QUESTION_STRUCT.CORRECT_ANSWERS]
              ).findIndex((correctAnswer: string) => correctAnswer === answer);
              const isAnswerCorrect = answerCorrectIdx > -1;
              return (
                <div
                  key={`answer_${index}`}
                  className={styles.answerItemContainer}
                >
                  <Checkbox
                    id={`checkbox-${index}`}
                    onChange={() => {
                      onQuestionAnswerChange(
                        currQuestion[QUESTION_STRUCT.ID],
                        currQuestion[QUESTION_STRUCT.TYPE_ID],
                        answer
                      );
                    }}
                    checked={isAnswerSelected(answer)}
                    className={styles.checkbox}
                    disabled={isTestEnded}
                  />
                  <div className={styles.row}>
                    {isTestEnded && isAnswerCorrect && (
                      <div className={styles.correctAnswer}>
                        {"(Правильный ответ)"}
                      </div>
                    )}
                    <div
                      className={`${
                        isTestEnded
                          ? styles.answerTextContainerDisabled
                          : styles.answerTextContainer
                      }`}
                      onClick={() => {
                        if (!isTestEnded) {
                          onQuestionAnswerChange(
                            currQuestion[QUESTION_STRUCT.ID],
                            currQuestion[QUESTION_STRUCT.TYPE_ID],
                            answer
                          );
                        }
                      }}
                    >
                      {answer}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        );
      case QUESTION_TYPES_ID.ONE_OF_MANY:
        return (
          <>
            {answers.map((answer: string, index: number) => {
              const answerCorrectIdx = JSON.parse(
                currQuestion[QUESTION_STRUCT.CORRECT_ANSWERS]
              ).findIndex((correctAnswer: string) => correctAnswer === answer);
              const isAnswerCorrect = answerCorrectIdx > -1;
              return (
                <div
                  key={`answer_${index}`}
                  className={styles.answerItemContainer}
                >
                  <RadioButton
                    id={`radio-button-${index}`}
                    onChange={() => {
                      onQuestionAnswerChange(
                        currQuestion[QUESTION_STRUCT.ID],
                        currQuestion[QUESTION_STRUCT.TYPE_ID],
                        answer
                      );
                    }}
                    checked={isAnswerSelected(answer)}
                    className={styles.checkbox}
                    disabled={isTestEnded}
                  />
                  <div className={styles.row}>
                    {isTestEnded && isAnswerCorrect && (
                      <div className={styles.correctAnswer}>
                        {"(Правильный ответ)"}
                      </div>
                    )}
                    <div
                      className={`${
                        isTestEnded
                          ? styles.answerTextContainerDisabled
                          : styles.answerTextContainer
                      }`}
                      onClick={() => {
                        if (!isTestEnded) {
                          onQuestionAnswerChange(
                            currQuestion[QUESTION_STRUCT.ID],
                            currQuestion[QUESTION_STRUCT.TYPE_ID],
                            answer
                          );
                        }
                      }}
                    >
                      {answer}
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        );
      case QUESTION_TYPES_ID.MATCHING:
        const elements = JSON.parse(currQuestion[QUESTION_STRUCT.ELEMENTS]);
        const answersOptions = answers.map((answer: string, index: number) => {
          return {
            id: index + 1,
            title: answer,
            disabled: new Set(currentQuestionResults).has(answer),
          };
        });
        return (
          <>
            {elements.map((element: string, index: number) => {
              return (
                <div
                  key={`answer_${currQuestion[QUESTION_STRUCT.ID]}_${index}`}
                  className={styles.answerItemContainer}
                >
                  <div
                    className={
                      isTestEnded
                        ? styles.answerTextContainerDisabled
                        : styles.answerTextContainerSelector
                    }
                  >
                    {element}
                  </div>
                  {/* @ts-ignore */}
                  <Select
                    onChange={(value: any) => {
                      onQuestionAnswerChange(
                        currQuestion[QUESTION_STRUCT.ID],
                        currQuestion[QUESTION_STRUCT.TYPE_ID],
                        answersOptions[value - 1][VOCABULARY_STRUCT.TITLE],
                        element
                      );
                    }}
                    options={answersOptions}
                    value={selectedAnswerValue(index, answersOptions)}
                    className={styles.selectMargin}
                    placeholder="Выберите соответствие из списка"
                    disabled={isTestEnded}
                    selectablePlaceholder
                    onReset={() => {
                      onSelectableQuestionAnswerReset(
                        currQuestion[QUESTION_STRUCT.ID],
                        element
                      );
                    }}
                  />
                  {isTestEnded && (
                    <div
                      className={`${styles.correctAnswer} ${styles.correctAnswerSelector}`}
                    >
                      {`(Правильный ответ: ${
                        JSON.parse(
                          currQuestion[QUESTION_STRUCT.CORRECT_ANSWERS]
                        )[index]
                      })`}
                    </div>
                  )}
                </div>
              );
            })}
          </>
        );
    }
  }, [
    currQuestion,
    currentQuestionResults,
    isAnswerSelected,
    isTestEnded,
    onQuestionAnswerChange,
    onSelectableQuestionAnswerReset,
    selectedAnswerValue,
  ]);

  return (
    <div>
      <div className={styles.lineCategoryText}>
        {`${currQuestion[QUESTION_STRUCT.LINE]} - ${
          currQuestion[QUESTION_STRUCT.CATEGORY]
        }`}
      </div>
      <div className={styles.titleText}>
        {currQuestion[QUESTION_STRUCT.TITLE]}
      </div>
      <div className={styles.typeText}>
        {/* @ts-ignore */}
        {QUESTION_TYPE_TITLE[currQuestion[QUESTION_STRUCT.TYPE_ID]]}
      </div>
      <div className={styles.answersContainer}>{answersComponent()}</div>
    </div>
  );
};

export default TestDialogQuestion;
