import React from "react";
import PropTypes from "prop-types";
//styles
import styles from "./index.module.scss";

const TabButton = ({
  isActive,
  onClick,
  children,
  className,
  icon: Icon,
  shortMode,
}) => {
  let mainCLass = `${className} ${styles.tab} `;

  mainCLass += (isActive ? styles.active : "") + " ";
  mainCLass += shortMode ? styles.short : "";

  return (
    <div className={mainCLass} onClick={onClick}>
      {/* {Icon && <Icon className={styles.icon} />} */}
      {isActive && <div className={styles.activeLine} />}
      {!shortMode && children}
    </div>
  );
};

TabButton.propTypes = {
  isActive: PropTypes.bool,
  shortMode: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.any.isRequired,
};

TabButton.defaultProps = {
  className: "",
  shortMode: false,
  isActive: false,
};

export default TabButton;
