import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { branch } from "baobab-react/higher-order";

import _ from "lodash";

import {
  meSelector,
  testsSelector,
  questionsSelector,
  categoriesSelector,
  testsResultsSelector,
} from "../../../store/struct/selectors";
import TEST_STRUCT, {
  TEST_STATUSES,
} from "../../../store/struct/entities/test";
import TEST_RESULT from "../../../store/struct/entities/testResult";
import USER_STRUCT from "../../../store/struct/entities/user";
import QUESTION_STRUCT, {
  QUESTION_TYPES_ID,
} from "../../../store/struct/entities/question";

import { getUTCDate } from "../../../utils/time";

import Table, { TYPES as TABLE_TYPES } from "../../../components/table";

import styles from "./index.module.scss";
import TestDialog from "./testDialog";
import {
  createTestResult,
  updateTestResult,
  updateTestResultWithoutConfirmation,
} from "../../../store/struct/entities/testResult/actions";
import {
  arraysAreEqualWithRightOrder,
  filterByIds,
  getRandomElementsByLineCategory,
  haveSameElementsWithDuplicates,
} from "../../../utils";

const HEADERS = ["№", "Тест", "Начало", "Окончание", "Статус"];

interface TestsProps {
  currentUser: any;
  tests: any;
  questions: any;
  dispatch: any;
  testsResults: any;
  categories: any;
}

const Tests: FC<TestsProps> = ({
  currentUser,
  tests,
  questions,
  dispatch,
  testsResults,
  categories,
}) => {
  const [showTestDialog, setShowTestDialog] = useState<any>(false);
  const [currentTestId, setCurrentTestId] = useState<number | null>(null);
  const [refresh, setRefresh] = useState<number>(Date.now());

  useEffect(() => {
    const unsubscribe = setInterval(() => setRefresh(Date.now()), 60000);
    return () => clearInterval(unsubscribe);
  }, []);

  const availableTests = useMemo(() => {
    return tests
      .map((test: any) => {
        const tempTestObj = { ...test };
        tempTestObj[TEST_STRUCT.START_DATE] =
          tempTestObj[TEST_STRUCT.START_DATE].replace(" ", "T") + "Z";
        tempTestObj[TEST_STRUCT.END_DATE] =
          tempTestObj[TEST_STRUCT.END_DATE].replace(" ", "T") + "Z";
        return tempTestObj;
      })
      .filter((test: any) => {
        const startDate = getUTCDate(test[TEST_STRUCT.START_DATE]);
        const now = getUTCDate(Date.now());
        return now >= startDate;
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tests, refresh]);

  useEffect(() => {
    const currentTestResult = testsResults.find((result: any) => {
      return (
        result[TEST_RESULT.REPRESENTATIVE_ID] === currentUser[USER_STRUCT.ID] &&
        (!result[TEST_RESULT.ACTUAL_END] ||
          result[TEST_RESULT.STATUS] === TEST_STATUSES.IN_PROGRESS)
      );
    });
    if (currentTestResult) {
      setShowTestDialog(true);
      setCurrentTestId(currentTestResult[TEST_RESULT.TEST_ID]);
    }
  }, [currentUser, testsResults]);

  const startTest = (data: any) => {
    const questionsInTest = filterByIds(
      questions,
      JSON.parse(data[TEST_STRUCT.QUESTIONS])
    );

    const userQuestions = getRandomElementsByLineCategory(
      questionsInTest,
      JSON.parse(data[TEST_STRUCT.QUESTIONS_COUNT_IN_CATEGORIES]),
      categories
    );
    let answersEmptyData: any = {};

    userQuestions.forEach((item: number) => {
      answersEmptyData[item] = null;
    });

    dispatch(createTestResult, {
      representative_id: currentUser[USER_STRUCT.ID],
      test_id: data[TEST_STRUCT.ID],
      status: TEST_STATUSES.IN_PROGRESS,
      answers: JSON.stringify(answersEmptyData),
      user_questions: JSON.stringify(userQuestions),
    });
  };

  const openTestDialog = (data: any) => {
    setShowTestDialog(true);
    setCurrentTestId(data[TEST_STRUCT.ID]);
  };

  const closeTestDialog = () => {
    setShowTestDialog(false);
    setCurrentTestId(null);
  };

  const tableConfig = [
    {
      type: TABLE_TYPES.TEXT,
      getValue: (_: any, index: number) => index + 1,
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: (data: any) => data[TEST_STRUCT.TITLE],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: (data: any) => {
        const date = getUTCDate(data[TEST_STRUCT.START_DATE]);
        return `${
          date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
        }.${
          date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
        }.${date.getFullYear()} ${
          date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
        }:${
          date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
        }`;
      },
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: (data: any) => {
        const date = getUTCDate(data[TEST_STRUCT.END_DATE]);
        return `${
          date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
        }.${
          date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
        }.${date.getFullYear()} ${
          date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
        }:${
          date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
        }`;
      },
    },
    {
      type: TABLE_TYPES.CONDITIONAL,
      getComponentIndex: (data: any) => {
        const now = getUTCDate(Date.now());
        const endDate = getUTCDate(data[TEST_STRUCT.END_DATE]);
        const testId = data[TEST_STRUCT.ID];
        const currentResults =
          testsResults.find(
            (result: any) =>
              result[TEST_RESULT.TEST_ID] === testId &&
              result[TEST_RESULT.REPRESENTATIVE_ID] ===
                currentUser[USER_STRUCT.ID]
          ) ?? {};

        const testStatusesMap = {
          [TEST_STATUSES.IN_PROGRESS]: 0,
          [TEST_STATUSES.DONE]: 1,
        };

        if (now > endDate && _.isEmpty(currentResults)) {
          return 2;
        } else {
          return testStatusesMap[currentResults[TEST_RESULT.STATUS]] ?? 0;
        }
      },
      components: [
        {
          type: TABLE_TYPES.TEXT,
          className: `${styles.noMargin} ${styles.centerText} ${styles.pressableText} ${styles.startTestButtonText}`,
          pressable: true,
          onClick: startTest,
          getValue: () => "Пройти",
          getProps: (data: any) => {
            const now = getUTCDate(Date.now());
            const testEndTime = getUTCDate(data[TEST_STRUCT.END_DATE]);
            return {
              disabled: now > testEndTime,
            };
          },
        },
        {
          type: TABLE_TYPES.TEXT,
          getValue: (data: any) => {
            const testId = data[TEST_STRUCT.ID];
            const currentResults = testsResults.find(
              (result: any) =>
                result[TEST_RESULT.TEST_ID] === testId &&
                result[TEST_RESULT.REPRESENTATIVE_ID] ===
                  currentUser[USER_STRUCT.ID]
            );
            return `${currentResults[TEST_RESULT.TEST_PERCENT]}%`;
          },
          pressable: true,
          onClick: openTestDialog,
          className: `${styles.noMargin} ${styles.centerText} ${styles.pressableText} ${styles.startTestButtonText}`,
        },
        {
          type: TABLE_TYPES.TEXT,
          getValue: () => "Не пройден",
          className: `${styles.noMargin} ${styles.centerText}`,
        },
      ],
    },
  ];

  const testsHeaderConfig = [
    {
      style: { className: styles.idTitle },
    },
    {
      style: { className: styles.wideTitle },
    },
    {
      style: { className: styles.dateTitle },
    },
    {
      style: { className: styles.dateTitle },
    },
    {
      style: { className: `${styles.narrowTitle} ${styles.centerText}` },
    },
  ];

  const currentTest = useMemo(() => {
    return availableTests.find((test: any) => {
      return test[TEST_STRUCT.ID] === currentTestId;
    });
  }, [availableTests, currentTestId]);

  const currTestResults = useMemo(() => {
    return (
      testsResults.find((testResults: any) => {
        return (
          testResults[TEST_RESULT.REPRESENTATIVE_ID] ===
            currentUser[USER_STRUCT.ID] &&
          testResults[TEST_RESULT.TEST_ID] === currentTestId
        );
      }) ?? {}
    );
  }, [currentTestId, currentUser, testsResults]);

  const testQuestions = useMemo(() => {
    const currentQuestions = currTestResults[TEST_RESULT.USER_QUESTIONS]
      ? JSON.parse(currTestResults[TEST_RESULT.USER_QUESTIONS])
      : [];
    return currentQuestions;
  }, [currTestResults]);

  const onSaveTest = useCallback(
    (testResults: any, functionAfter?: any, isTimeEnd?: boolean) => {
      const results = Object.values(testResults);
      const answeredQuestions =
        results.length -
        results.filter((result: any) => {
          return result === null || result.length === 0;
        }).length;

      let currentTestCorrectAnswersPoints = 0;

      let resultsPoints: any = {};

      for (const questionId in testResults) {
        const questionInfo = questions.find((question: any) => {
          return question[QUESTION_STRUCT.ID] === Number(questionId);
        });

        switch (questionInfo[QUESTION_STRUCT.TYPE_ID]) {
          case QUESTION_TYPES_ID.ONE_OF_MANY:
            if (
              testResults[questionId] &&
              testResults[questionId][0] ===
                JSON.parse(questionInfo[QUESTION_STRUCT.CORRECT_ANSWERS])[0]
            ) {
              currentTestCorrectAnswersPoints += 1;
              resultsPoints[questionId] = 1;
            } else {
              resultsPoints[questionId] = 0;
            }
            break;
          case QUESTION_TYPES_ID.MANY_OF_MANY:
            if (
              testResults[questionId] &&
              haveSameElementsWithDuplicates(
                testResults[questionId],
                JSON.parse(questionInfo[QUESTION_STRUCT.CORRECT_ANSWERS])
              )
            ) {
              currentTestCorrectAnswersPoints += 1;
              resultsPoints[questionId] = 1;
            } else {
              resultsPoints[questionId] = 0;
            }
            break;

          case QUESTION_TYPES_ID.MATCHING:
            if (
              testResults[questionId] &&
              arraysAreEqualWithRightOrder(
                testResults[questionId],
                JSON.parse(questionInfo[QUESTION_STRUCT.CORRECT_ANSWERS])
              )
            ) {
              currentTestCorrectAnswersPoints += 1;
              resultsPoints[questionId] = 1;
            } else {
              resultsPoints[questionId] = 0;
            }
            break;
        }
      }

      const testPercent = Math.round(
        (currentTestCorrectAnswersPoints * 100) / results.length
      );

      if (isTimeEnd) {
        dispatch(updateTestResultWithoutConfirmation, {
          id: currTestResults[TEST_RESULT.ID],
          test_id: currTestResults[TEST_RESULT.TEST_ID],
          status: TEST_STATUSES.DONE,
          answers: JSON.stringify(testResults),
          test_percent: testPercent,
          answered_questions: answeredQuestions,
          result_points: JSON.stringify(resultsPoints),
        });
      } else if (functionAfter) {
        dispatch(
          updateTestResult,
          {
            id: currTestResults[TEST_RESULT.ID],
            test_id: currTestResults[TEST_RESULT.TEST_ID],
            status: TEST_STATUSES.DONE,
            answers: JSON.stringify(testResults),
            test_percent: testPercent,
            answered_questions: answeredQuestions,
            result_points: JSON.stringify(resultsPoints),
          },
          functionAfter
        );
      } else {
        dispatch(updateTestResultWithoutConfirmation, {
          id: currTestResults[TEST_RESULT.ID],
          test_id: currTestResults[TEST_RESULT.TEST_ID],
          answers: JSON.stringify(testResults),
          test_percent: testPercent,
          answered_questions: answeredQuestions,
          result_points: JSON.stringify(resultsPoints),
        });
      }
    },
    [currTestResults, dispatch, questions]
  );

  return (
    <>
      {availableTests.length > 0 ? (
        <Table
          headers={HEADERS}
          config={tableConfig}
          headerConfig={testsHeaderConfig}
          data={availableTests}
        />
      ) : (
        <div>Список тестов пуст</div>
      )}
      {showTestDialog && (
        <TestDialog
          currentTestId={currentTestId}
          test={currentTest}
          testQuestions={testQuestions}
          currentTestResults={currTestResults}
          onSaveTest={onSaveTest}
          closeTestDialog={closeTestDialog}
        />
      )}
    </>
  );
};

export default branch(
  {
    currentUser: meSelector(),
    tests: testsSelector(),
    questions: questionsSelector(),
    testsResults: testsResultsSelector(),
    categories: categoriesSelector(),
  },
  Tests
);
