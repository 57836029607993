import STRUCT from ".";
import ENTITIES from "./entities";

export const agentsSelector = () => [STRUCT.ENTITIES, ENTITIES.AGENT];
export const meSelector = () => [STRUCT.ENTITIES, ENTITIES.ME];
export const reportsSelector = () => [STRUCT.ENTITIES, ENTITIES.REPORTS];
export const questionsSelector = () => [STRUCT.ENTITIES, ENTITIES.QUESTIONS];
export const categoriesSelector = () => [STRUCT.ENTITIES, ENTITIES.CATEGORIES];
export const directionsSelector = () => [STRUCT.ENTITIES, ENTITIES.DIRECTIONS];
export const linesSelector = () => [STRUCT.ENTITIES, ENTITIES.LINES];
export const testsSelector = () => [STRUCT.ENTITIES, ENTITIES.TESTS];
export const testsResultsSelector = () => [
  STRUCT.ENTITIES,
  ENTITIES.TESTS_RESULTS,
];
