const STRUCT = {
  ID: "id",
  TITLE: "title",
};

export const ROLES = {
  ADMIN: 1,
  AGENT: 2,
};

export default STRUCT;
