import { initialState as entitiesInitialState } from "./entities";
import { initialState as appInitialState } from "./app";

const STRUCT = {
  ENTITIES: "entities",
  REMOVED_ENTITIES: "removed_entities",
  CALCULATED: "calculated",

  // Local state
  APP: "app",
  CURRENT_TEAMS: "curr_teams",
  CURRENT_AGENTS: "curr_agents",
};

export default STRUCT;

export const initialState = () => ({
  [STRUCT.ENTITIES]: entitiesInitialState(),
  [STRUCT.REMOVED_ENTITIES]: entitiesInitialState(),
  [STRUCT.APP]: appInitialState(),
});
