import React from "react";
import { branch } from "baobab-react/higher-order";
import { meSelector } from "../../../store/struct/selectors";
import USER_STRUCT from "../../../store/struct/entities/user";

import styles from "./index.module.scss";

const UserInfo = (props) => {
  const { user } = props;

  if (!user) {
    return null;
  }

  return user && <div className={styles.email}>{user[USER_STRUCT.EMAIL]}</div>;
};

export default branch(
  {
    user: meSelector(),
  },
  UserInfo
);
