import React, { useMemo, useState, useCallback, FC, createRef } from "react";
import { branch } from "baobab-react/higher-order";

import Dialog from "../../../components/dialog";

import {
  createTest,
  updateTest,
  deleteTest,
} from "../../../store/struct/entities/test/actions";
import { sendTestPins } from "../../../store/struct/entities/admin/actions";
import TEST_STRUCT from "../../../store/struct/entities/test";
import USER_STRUCT from "../../../store/struct/entities/user";
import QUESTION_STRUCT from "../../../store/struct/entities/question";
import VOCABULARY_STRUCT, {
  CALCULATED_LINE_CATEGORY_STRUCT,
} from "../../../store/struct/entities/vocabulary";

import styles from "./index.module.scss";
import TestQuestions from "./questions";
import TestUsers from "./users";
import TestSettings from "./settings";
import _ from "lodash";
import {
  questionsSelector,
  categoriesSelector,
  linesSelector,
} from "../../../store/struct/selectors";
import { getUTCDate } from "../../../utils/time";
import { THEMES } from "../../../components/button";

const BTNS = {
  BACK: "back",
  NEXT: "next",
  SAVE: "save",
  SEND_PINS: "send_pins",
  REMOVE: "remove",
  CANCEL: "cancel",
};

const STEPS = {
  QUESTIONS: 0,
  USERS: 1,
  SETTINGS: 2,
};

interface CreateTestDialogProps {
  questions: any;
  dispatch: any;
  testToChangeSettings: any;
  categories: any;
  lines: any;
  closeCreateDialog: () => void;
}

interface StepProps {
  step: number;
  current: number;
  children: any;
}

const Step: FC<StepProps> = ({ step, current, children }) => (
  <div className={step === current ? "" : styles.hidden}>{children}</div>
);

const CreateTestDialog: FC<CreateTestDialogProps> = ({
  questions,
  dispatch,
  testToChangeSettings,
  categories,
  closeCreateDialog,
  lines,
}) => {
  const [step, setStep] = useState<number>(0);
  const [selectedQuestions, setSelctedQuestions] = useState<number[]>(
    testToChangeSettings
      ? JSON.parse(testToChangeSettings[TEST_STRUCT.QUESTIONS])
      : []
  );
  const [selectedUsers, setSelctedUsers] = useState<number[]>(
    testToChangeSettings
      ? JSON.parse(testToChangeSettings[TEST_STRUCT.USERS])
      : []
  );

  const settingsIsDisabled = useMemo(() => {
    if (testToChangeSettings) {
      const now = getUTCDate(Date.now());
      const testStartTime = getUTCDate(
        testToChangeSettings[TEST_STRUCT.START_DATE]
      );
      return now > testStartTime;
    } else {
      return false;
    }
  }, [testToChangeSettings]);

  const categoriesToChange = useMemo(() => {
    return testToChangeSettings
      ? Object.keys(
          JSON.parse(
            testToChangeSettings[TEST_STRUCT.QUESTIONS_COUNT_IN_CATEGORIES]
          )
        ).reduce((acc: any, current: any) => {
          const [lineId, categoryId] = current.split("-");
          const currentLine = lines.find(
            (currCategory: any) =>
              currCategory[VOCABULARY_STRUCT.ID] === Number(lineId)
          );
          const currentCategory = categories.find(
            (currCategory: any) =>
              currCategory[VOCABULARY_STRUCT.ID] === Number(categoryId)
          );

          const lineCategoryTitle = `${
            currentLine ? currentLine[VOCABULARY_STRUCT.TITLE] : "N/A"
          }-${
            currentCategory ? currentCategory[VOCABULARY_STRUCT.TITLE] : "N/A"
          }`;

          acc[
            `${TEST_STRUCT.QUESTIONS_COUNT_IN_CATEGORIES}_${lineCategoryTitle}`
          ] = `${
            JSON.parse(
              testToChangeSettings[TEST_STRUCT.QUESTIONS_COUNT_IN_CATEGORIES]
            )[current]
          }`;
          return acc;
        }, {})
      : {};
  }, [categories, lines, testToChangeSettings]);

  const settingsToChange = testToChangeSettings
    ? Object.assign(
        {
          [TEST_STRUCT.TITLE]: testToChangeSettings[TEST_STRUCT.TITLE],
          [TEST_STRUCT.DURATION]: `${
            testToChangeSettings[TEST_STRUCT.DURATION]
          }`,
          [TEST_STRUCT.GOAL_PERCENT]: `${
            testToChangeSettings[TEST_STRUCT.GOAL_PERCENT]
          }`,
          [TEST_STRUCT.START_DATE]:
            testToChangeSettings[TEST_STRUCT.START_DATE],
          [TEST_STRUCT.END_DATE]: testToChangeSettings[TEST_STRUCT.END_DATE],
        },
        categoriesToChange
      )
    : {};

  const [settingFormData, setSettingFormData] = useState<any>(settingsToChange);

  const [currentLine, setCurrentLine] = useState<string | null>(null);
  const [currentDirection, setCurrentDirection] = useState<string | null>(null);
  const [currentQuestionDate, setCurrentQuestionDate] = useState<string | null>(
    null
  );

  const testSettingsFormRef = createRef<any>();

  const stepOrders = useMemo(() => {
    const arr = [STEPS.QUESTIONS, STEPS.USERS, STEPS.SETTINGS];
    return arr;
  }, []);

  const [errorValidationDuration, setErrorValidationDuration] =
    useState<boolean>(false);
  const [errorValidationGoal, setErrorValidationGoal] =
    useState<boolean>(false);
  const [errorValidationStartDate, setErrorValidationStartDate] =
    useState<boolean>(false);
  const [errorValidationEndDate, setErrorValidationEndDate] =
    useState<boolean>(false);
  const [errorValidationCategoryCount, setErrorValidationCategoryCount] =
    useState<any>({});
  const validateDuration = (value: number) => {
    if (value < 1) {
      setErrorValidationDuration(true);
    } else {
      setErrorValidationDuration(false);
    }
  };

  const validateStartDate = (value: any) => {
    const now = getUTCDate(Date.now());
    if (value > now) {
      setErrorValidationStartDate(false);
    } else {
      setErrorValidationStartDate(true);
    }
  };
  const validateEndDate = (value: any) => {
    const startDate = getUTCDate(
      settingFormData[TEST_STRUCT.START_DATE].getTime() +
        settingFormData[TEST_STRUCT.DURATION] * 60000
    );
    if (value > startDate) {
      setErrorValidationEndDate(false);
    } else {
      setErrorValidationEndDate(true);
    }
  };

  const validateGoal = (value: number) => {
    if (value < 1 || value > 100) {
      setErrorValidationGoal(true);
    } else {
      setErrorValidationGoal(false);
    }
  };
  const validateCategoryCount = (value: number, id: string, count: number) => {
    const tempValidationObj = { ...errorValidationCategoryCount };
    if (value > count || value < 0 || !value.toString().match(/^\d+$/)) {
      tempValidationObj[id] = true;
    } else {
      tempValidationObj[id] = false;
    }
    setErrorValidationCategoryCount(tempValidationObj);
  };

  const selectedQuestionsLineCategoriesCount = useMemo(() => {
    const questionsCategoriesCount = selectedQuestions
      ? selectedQuestions.reduce((acc: any, currentQuestionId: number) => {
          const currentQuestion = questions.find(
            (question: any) =>
              question[QUESTION_STRUCT.ID] === currentQuestionId
          );
          if (
            acc[
              `${currentQuestion[QUESTION_STRUCT.LINE_ID]}-${
                currentQuestion[QUESTION_STRUCT.CATEGORY_ID]
              }`
            ]
          ) {
            acc[
              `${currentQuestion[QUESTION_STRUCT.LINE_ID]}-${
                currentQuestion[QUESTION_STRUCT.CATEGORY_ID]
              }`
            ] += 1;
          } else {
            acc[
              `${currentQuestion[QUESTION_STRUCT.LINE_ID]}-${
                currentQuestion[QUESTION_STRUCT.CATEGORY_ID]
              }`
            ] = 1;
          }
          return acc;
        }, {})
      : [];
    const setSelectedQuestions = new Set(selectedQuestions);

    const selectedQuestionsData = questions.filter((question: any) =>
      setSelectedQuestions.has(question[QUESTION_STRUCT.ID])
    );

    const lineAndCategorySelectedQuestionsData = selectedQuestionsData
      .map((value: any) => ({
        lineId: value[QUESTION_STRUCT.LINE_ID],
        categoryId: value[QUESTION_STRUCT.CATEGORY_ID],
        title: `${value[QUESTION_STRUCT.LINE]}-${
          value[QUESTION_STRUCT.CATEGORY]
        }`,
      }))
      .filter((object: any, index: number, self: any) => {
        return (
          index ===
          self.findIndex(
            (obj: any) => JSON.stringify(obj) === JSON.stringify(object)
          )
        );
      })
      .map((currentItem: any) => {
        return {
          ...currentItem,
          count:
            questionsCategoriesCount[
              `${currentItem.lineId}-${currentItem.categoryId}`
            ] ?? 0,
        };
      });

    return lineAndCategorySelectedQuestionsData;
  }, [questions, selectedQuestions]);

  const onSaveTest = useCallback(() => {
    const questionsCountInCategories =
      selectedQuestionsLineCategoriesCount.reduce((acc: any, current: any) => {
        acc[
          `${current[CALCULATED_LINE_CATEGORY_STRUCT.LINE_ID]}-${
            current[CALCULATED_LINE_CATEGORY_STRUCT.CATEGORY_ID]
          }`
        ] = Number(
          settingFormData[
            `${TEST_STRUCT.QUESTIONS_COUNT_IN_CATEGORIES}_${
              current[VOCABULARY_STRUCT.TITLE]
            }`
          ]
        );
        return acc;
      }, {});

    const data = {
      [TEST_STRUCT.TITLE]: settingFormData[TEST_STRUCT.TITLE],
      [TEST_STRUCT.DURATION]: Number(settingFormData[TEST_STRUCT.DURATION]),
      [TEST_STRUCT.GOAL_PERCENT]: Number(
        settingFormData[TEST_STRUCT.GOAL_PERCENT]
      ),
      [TEST_STRUCT.START_DATE]: settingFormData[TEST_STRUCT.START_DATE],
      [TEST_STRUCT.END_DATE]: settingFormData[TEST_STRUCT.END_DATE],
      [TEST_STRUCT.QUESTIONS]: JSON.stringify(selectedQuestions),
      [TEST_STRUCT.USERS]: JSON.stringify(selectedUsers),
      [TEST_STRUCT.QUESTIONS_COUNT_IN_CATEGORIES]: JSON.stringify(
        questionsCountInCategories
      ),
    };

    if (testToChangeSettings) {
      const dataToUpdate = Object.assign(data, {
        [TEST_STRUCT.ID]: testToChangeSettings[TEST_STRUCT.ID],
      });
      dispatch(updateTest, dataToUpdate);
    } else {
      dispatch(createTest, data);
    }

    closeCreateDialog();
  }, [
    closeCreateDialog,
    dispatch,
    selectedQuestions,
    selectedQuestionsLineCategoriesCount,
    selectedUsers,
    settingFormData,
    testToChangeSettings,
  ]);

  const handleDeleteTest = useCallback(
    (testId: number) => {
      dispatch(
        deleteTest,
        {
          [TEST_STRUCT.ID]: testId,
        },
        closeCreateDialog
      );
    },
    [closeCreateDialog, dispatch]
  );

  const sendPins = useCallback(
    (testId: number) => {
      dispatch(sendTestPins, testId);
    },
    [dispatch]
  );

  const handleClick = useCallback(
    (id: string) => {
      if (id === BTNS.BACK) {
        setStep(step - 1);
      }
      if (id === BTNS.NEXT) {
        setStep(step + 1);
      }
      if (id === BTNS.SAVE) {
        onSaveTest();
      }
      if (id === BTNS.SEND_PINS) {
        testToChangeSettings && sendPins(testToChangeSettings[TEST_STRUCT.ID]);
      }
      if (id === BTNS.REMOVE) {
        testToChangeSettings &&
          handleDeleteTest(testToChangeSettings[TEST_STRUCT.ID]);
      }
    },
    [handleDeleteTest, onSaveTest, sendPins, step, testToChangeSettings]
  );

  const ifOneCategoryCountHaveError = useMemo(() => {
    let hasError = _.isEmpty(categoriesToChange);

    if (
      Object.values(errorValidationCategoryCount).length ===
      selectedQuestionsLineCategoriesCount.length
    )
      if (Object.values(errorValidationCategoryCount).includes(true)) {
        hasError = true;
      } else {
        hasError = false;
      }

    return hasError;
  }, [
    categoriesToChange,
    errorValidationCategoryCount,
    selectedQuestionsLineCategoriesCount.length,
  ]);

  const buttonsConfig = useMemo(() => {
    let btns = [];

    if (step > 0) {
      btns.push({
        id: BTNS.BACK,
        title: "Назад",
      });
    }
    if (step < stepOrders.length - 1) {
      btns.push({
        id: BTNS.NEXT,
        title: "Далее",
        disabled:
          (stepOrders[step] === STEPS.QUESTIONS &&
            selectedQuestions.length < 1) ||
          (stepOrders[step] === STEPS.USERS && selectedUsers.length < 1),
      });
    }
    if (step === stepOrders.length - 1) {
      if (testToChangeSettings) {
        btns.unshift({
          id: BTNS.REMOVE,
          title: "Удалить",
          theme: THEMES.NEW_ERROR,
          disabled: settingsIsDisabled,
        });
      }
      btns.unshift({
        id: BTNS.SEND_PINS,
        title: "Выслать пины",
        disabled:
          settingsIsDisabled ||
          !testToChangeSettings ||
          !testToChangeSettings[TEST_STRUCT.ID],
      });
      btns.push({
        id: BTNS.SAVE,
        title: "Сохранить",
        theme: THEMES.NEW_SUCCESS,
        disabled:
          settingsIsDisabled ||
          (stepOrders[step] === STEPS.SETTINGS &&
            (errorValidationDuration ||
              errorValidationGoal ||
              errorValidationStartDate ||
              errorValidationEndDate ||
              _.isEmpty(settingFormData[TEST_STRUCT.TITLE]) ||
              _.isEmpty(settingFormData[TEST_STRUCT.DURATION]) ||
              _.isEmpty(settingFormData[TEST_STRUCT.GOAL_PERCENT]) ||
              !settingFormData[TEST_STRUCT.START_DATE] ||
              !settingFormData[TEST_STRUCT.END_DATE] ||
              ifOneCategoryCountHaveError)),
      });
    }
    return btns;
  }, [
    errorValidationDuration,
    errorValidationEndDate,
    errorValidationGoal,
    errorValidationStartDate,
    ifOneCategoryCountHaveError,
    selectedQuestions.length,
    selectedUsers.length,
    settingFormData,
    settingsIsDisabled,
    step,
    stepOrders,
    testToChangeSettings,
  ]);

  const handleSettingFormData = (data: any) => {
    setSettingFormData(data);
  };

  const toggleSelectedQuestion = (questionId: number) => {
    let newState = [...selectedQuestions];

    const index = selectedQuestions.findIndex(
      (selectedQuestionId: number) => selectedQuestionId === questionId
    );

    if (index > -1) {
      newState = [...newState.slice(0, index), ...newState.slice(index + 1)];
    } else {
      newState.push(questionId);
    }

    setSelctedQuestions(newState);
  };

  const resetSelectedQuestions = () => {
    setSelctedQuestions([]);
  };

  const resetSelectedUsers = () => {
    setSelctedUsers([]);
  };

  const toggleSelectedUser = (userId: number) => {
    let newState = [...selectedUsers];

    const index = selectedUsers.findIndex(
      (selectedUserId: number) => selectedUserId === userId
    );

    if (index > -1) {
      newState = [...newState.slice(0, index), ...newState.slice(index + 1)];
    } else {
      newState.push(userId);
    }

    setSelctedUsers(newState);
  };

  const toggleSelectAllUsers = (usersToSelect: any[]) => {
    let newState = [...selectedUsers];

    if (newState.length === usersToSelect.length) {
      setSelctedUsers([]);
    } else {
      newState = [];
      usersToSelect.forEach((agent: any) => {
        newState.push(agent[USER_STRUCT.ID]);
      });
      setSelctedUsers(newState);
    }
  };

  const toggleSelectAllQuestions = (questionsToSelect: any[]) => {
    let newState = [...selectedQuestions];

    const isAllQuestionSelected = questionsToSelect.every(
      (filteredQuestion: any) =>
        newState.some(
          (selectedQuestionId: number) =>
            filteredQuestion.id === selectedQuestionId
        )
    );

    const questionsToSelectIds = questionsToSelect.map(
      (questionToSelect: any) => questionToSelect[QUESTION_STRUCT.ID]
    );

    if (isAllQuestionSelected) {
      const setQuestionsToSelect = new Set(questionsToSelectIds);

      const updatedNewState = newState.filter(
        (num) => !setQuestionsToSelect.has(num)
      );
      setSelctedQuestions(updatedNewState);
    } else {
      const setNewState = new Set(newState);

      const newIds = questionsToSelectIds.filter(
        (num) => !setNewState.has(num)
      );

      const updatedNewState = newState.concat(newIds);
      setSelctedQuestions(updatedNewState);
    }
  };

  const handleSetCurrentLine = useCallback((line: string) => {
    setCurrentLine(line);
  }, []);

  const handleSetCurrentDirection = useCallback((direction: string) => {
    setCurrentDirection(direction);
  }, []);

  const handleSetCurrentQuestionDate = useCallback((date: string) => {
    setCurrentQuestionDate(date);
  }, []);

  return (
    // @ts-ignore
    <Dialog
      title={
        step === STEPS.QUESTIONS
          ? "Тест - Выбор вопросов"
          : step === STEPS.USERS
          ? "Тест - Выбор пользователей"
          : "Тест - Настройка"
      }
      onCancel={
        {
          0: closeCreateDialog,
          1: closeCreateDialog,
          2: closeCreateDialog,
        }[step]
      }
      cancelTitle={"Отмена"}
      buttons={buttonsConfig}
      onClick={handleClick}
      bodyClassName={styles.createTestDialogBody}
      fullScreen
    >
      <Step step={STEPS.QUESTIONS} current={stepOrders[step]}>
        <TestQuestions
          selectedQuestions={selectedQuestions}
          toggleSelectedQuestion={toggleSelectedQuestion}
          resetSelectedQuestions={resetSelectedQuestions}
          resetSelectedUsers={resetSelectedUsers}
          currentLine={currentLine}
          currentDirection={currentDirection}
          currentQuestionDate={currentQuestionDate}
          setCurrentQuestionDate={handleSetCurrentQuestionDate}
          setCurrentLine={handleSetCurrentLine}
          setCurrentDirection={handleSetCurrentDirection}
          settingsIsDisabled={settingsIsDisabled}
          toggleSelectAllQuestions={toggleSelectAllQuestions}
          testToChangeSettings={testToChangeSettings}
        />
      </Step>
      <Step step={STEPS.USERS} current={stepOrders[step]}>
        <TestUsers
          selectedUsers={selectedUsers}
          toggleSelectedUser={toggleSelectedUser}
          currentLine={currentLine}
          currentDirection={currentDirection}
          toggleSelectAllUsers={toggleSelectAllUsers}
          settingsIsDisabled={settingsIsDisabled}
        />
      </Step>
      <Step step={STEPS.SETTINGS} current={stepOrders[step]}>
        <TestSettings
          formRef={testSettingsFormRef}
          selectedQuestions={selectedQuestions}
          errorValidationDuration={errorValidationDuration}
          errorValidationGoal={errorValidationGoal}
          errorValidationStartDate={errorValidationStartDate}
          errorValidationEndDate={errorValidationEndDate}
          errorValidationCategoryCount={errorValidationCategoryCount}
          settingFormData={settingFormData}
          validateCategoryCount={validateCategoryCount}
          validateDuration={validateDuration}
          validateStartDate={validateStartDate}
          validateEndDate={validateEndDate}
          validateGoal={validateGoal}
          handleSettingFormData={handleSettingFormData}
          selectedQuestionsLineCategoriesCount={
            selectedQuestionsLineCategoriesCount
          }
          settingsIsDisabled={settingsIsDisabled}
        />
      </Step>
    </Dialog>
  );
};

export default branch(
  {
    questions: questionsSelector(),
    categories: categoriesSelector(),
    lines: linesSelector(),
  },
  CreateTestDialog
);
