import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { branch } from "baobab-react/higher-order";

import { questionsSelector } from "../../../store/struct/selectors";

import Dialog from "../../../components/dialog";
import TestDialogQuestion from "./question";

import QUESTION_STRUCT, {
  QUESTION_TYPES_ID,
} from "../../../store/struct/entities/question";
import TEST_STRUCT, {
  TEST_STATUSES,
} from "../../../store/struct/entities/test";
import TEST_RESULT from "../../../store/struct/entities/testResult";

import styles from "./index.module.scss";
import Timer from "../../../components/timer";
import { THEMES } from "../../../components/button";
import _ from "lodash";
import { getUTCDate } from "../../../utils/time";
import Table, { TYPES as TABLE_TYPES } from "../../../components/table";

interface TestDialogProps {
  test: any;
  testQuestions: number[];
  currentTestResults: any;
  questions: any;
  onSaveTest: (
    testResults: any,
    functionAfter?: any,
    isTimeEnd?: boolean
  ) => void;
  closeTestDialog: () => void;
}

const BTNS = {
  BACK: "back",
  NEXT: "next",
  SAVE: "save",
  RESULT_BACK: "result_back",
  RESULT_CLOSE: "result_close",
};

const STEPS = {
  QUESTIONS: 0,
  RESULT: 1,
};

const HEADERS = ["№", "Вопрос", "Правильный ответ", "Ваш ответ"];

const Step: FC<{ step: number; current: number; children: any }> = ({
  step,
  current,
  children,
}) => <div className={step === current ? "" : styles.hidden}>{children}</div>;

const TestDialog: FC<TestDialogProps> = ({
  test,
  testQuestions,
  currentTestResults,
  questions,
  onSaveTest,
  closeTestDialog,
}) => {
  // Get current UTC timestamp
  const nowUTCTime = getUTCDate(new Date()).getTime();
  // Convert actual time of test start to Date
  const actualStartDate = getUTCDate(
    currentTestResults[TEST_RESULT.ACTUAL_START]
  );

  const settingsTestEndDate = getUTCDate(test[TEST_STRUCT.END_DATE]);

  // Count time of test end
  const endTestTime = actualStartDate.setMinutes(
    actualStartDate.getMinutes() + test[TEST_STRUCT.DURATION]
  );
  // Get actual time until the end of test
  const actualStartTestTime = Math.trunc((endTestTime - nowUTCTime) / 1000);

  const TEST_TIME = test[TEST_STRUCT.DURATION] * 60;

  const [step, setStep] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [refresh, setRefresh] = useState<number>(Date.now());
  const updateRef = useRef<any>();

  useEffect(() => {
    updateRef.current = setInterval(() => setRefresh(Date.now()), 1000);
    return () => clearInterval(updateRef.current);
  }, []);

  const [testResults, setTestResults] = useState<any>(
    !_.isEmpty(currentTestResults)
      ? JSON.parse(currentTestResults[TEST_RESULT.ANSWERS])
      : {}
  );

  useEffect(() => {
    const now = getUTCDate(Date.now());
    const endTime = getUTCDate(endTestTime);
    const endSettingsTestTime = getUTCDate(settingsTestEndDate);

    if (
      step === STEPS.QUESTIONS &&
      currentTestResults[TEST_RESULT.STATUS] !== TEST_STATUSES.DONE &&
      (now >= endTime || now >= endSettingsTestTime)
    ) {
      onSaveTest(testResults, undefined, true);
      setStep(step + 1);
      clearInterval(updateRef.current);
    }
  }, [
    currentTestResults,
    endTestTime,
    onSaveTest,
    refresh,
    settingsTestEndDate,
    step,
    testResults,
  ]);

  const isTestEnded = useMemo(() => {
    return currentTestResults[TEST_RESULT.STATUS] === TEST_STATUSES.DONE;
  }, [currentTestResults]);

  const currQuestion = useMemo(() => {
    return questions.find((question: any) => {
      const currentQuestionId = testQuestions[currentQuestionIndex];
      return question[QUESTION_STRUCT.ID] === currentQuestionId;
    });
  }, [currentQuestionIndex, questions, testQuestions]);

  const stepOrders = useMemo(() => {
    return [STEPS.QUESTIONS, STEPS.RESULT];
  }, []);

  const buttonsConfig = useMemo(() => {
    let btns = [];

    if (stepOrders[step] === STEPS.QUESTIONS) {
      if (currentQuestionIndex > 0) {
        btns.push({
          id: BTNS.BACK,
          title: "Назад",
          theme: THEMES.NEW_PRIMARY,
        });
      }
      if (currentQuestionIndex < testQuestions.length - 1) {
        btns.push({
          id: BTNS.NEXT,
          title: "Далее",
          theme: THEMES.NEW_PRIMARY,
          disabled: !isTestEnded
            ? testResults[currQuestion[QUESTION_STRUCT.ID]]
              ? testResults[currQuestion[QUESTION_STRUCT.ID]].some(
                  (element: string) => element === null
                ) || testResults[currQuestion[QUESTION_STRUCT.ID]].length === 0
              : true
            : false,
        });
      }
      if (currentQuestionIndex === testQuestions.length - 1) {
        btns.push({
          id: BTNS.SAVE,
          title: `${isTestEnded ? "Далее" : "Завершить тест"}`,
          theme: isTestEnded ? THEMES.NEW_PRIMARY : THEMES.NEW_ERROR,
          disabled: !isTestEnded
            ? testResults[currQuestion[QUESTION_STRUCT.ID]]
              ? testResults[currQuestion[QUESTION_STRUCT.ID]].some(
                  (element: string) => element === null
                ) || testResults[currQuestion[QUESTION_STRUCT.ID]].length === 0
              : true
            : false,
        });
      }
    }

    if (stepOrders[step] === STEPS.RESULT) {
      btns.push({
        id: BTNS.RESULT_BACK,
        title: "Назад",
        theme: THEMES.NEW_PRIMARY,
      });
      btns.push({
        id: BTNS.RESULT_CLOSE,
        title: "Закрыть",
        theme: THEMES.NEW_PRIMARY,
      });
    }
    return btns;
  }, [
    currQuestion,
    currentQuestionIndex,
    isTestEnded,
    step,
    stepOrders,
    testQuestions.length,
    testResults,
  ]);

  const handleClick = useCallback(
    (id: string) => {
      if (id === BTNS.BACK) {
        setCurrentQuestionIndex(currentQuestionIndex - 1);
      }
      if (id === BTNS.NEXT) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
      if (id === BTNS.SAVE) {
        if (!isTestEnded) {
          onSaveTest(testResults, () => setStep(step + 1));
        } else {
          setStep(step + 1);
        }
      }
      if (id === BTNS.RESULT_BACK) {
        setStep(step - 1);
      }
      if (id === BTNS.RESULT_CLOSE) {
        closeTestDialog();
      }
    },
    [
      closeTestDialog,
      currentQuestionIndex,
      isTestEnded,
      onSaveTest,
      step,
      testResults,
    ]
  );

  const onQuestionAnswerChange = (
    questionId: number,
    questionType: number,
    value: any,
    element?: string
  ) => {
    let tempResults = { ...testResults };

    const currentQuestionResult = testResults[questionId]
      ? [...testResults[questionId]]
      : [];

    const currentQuestion = questions.find(
      (question: any) => question[QUESTION_STRUCT.ID] === questionId
    );

    const currentQuestionElements = JSON.parse(
      currentQuestion[QUESTION_STRUCT.ELEMENTS]
    );

    const currentQuestionElementsIdx = currentQuestionElements
      ? currentQuestionElements.findIndex(
          (questionElement: string) => questionElement === element
        )
      : -1;

    let newState = tempResults[questionId]
      ? [...tempResults[questionId]]
      : questionType === QUESTION_TYPES_ID.MATCHING
      ? Array(currentQuestionElements.length).fill(null)
      : [];

    const index = currentQuestionResult.findIndex(
      (answer: string) => answer === value
    );

    switch (questionType) {
      case QUESTION_TYPES_ID.MANY_OF_MANY:
        if (index > -1) {
          newState = [
            ...newState.slice(0, index),
            ...newState.slice(index + 1),
          ];
        } else {
          newState.push(value);
        }
        break;
      case QUESTION_TYPES_ID.ONE_OF_MANY:
        if (index > -1) {
          newState = [];
        } else {
          newState = [value];
        }
        break;
      case QUESTION_TYPES_ID.MATCHING:
        newState[currentQuestionElementsIdx] = value;
        break;
    }

    tempResults[questionId] = newState;

    setTestResults(tempResults);
    onSaveTest(tempResults);
  };

  const onSelectableQuestionAnswerReset = (
    questionId: number,
    element: string
  ) => {
    let tempResults = { ...testResults };

    let tempCurrentQuestionResult = testResults[questionId]
      ? [...testResults[questionId]]
      : [];

    const currentQuestion = questions.find(
      (question: any) => question[QUESTION_STRUCT.ID] === questionId
    );
    const currentQuestionElements = JSON.parse(
      currentQuestion[QUESTION_STRUCT.ELEMENTS]
    );
    const currentQuestionElementsIdx = currentQuestionElements
      ? currentQuestionElements.findIndex(
          (questionElement: string) => questionElement === element
        )
      : -1;

    tempCurrentQuestionResult[currentQuestionElementsIdx] = null;
    tempResults[questionId] = tempCurrentQuestionResult;

    setTestResults(tempResults);
    onSaveTest(tempResults);
  };

  const tableConfig = [
    {
      type: TABLE_TYPES.TEXT,
      getValue: (_: any, index: number) => index + 1,
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: (data: any) => data[QUESTION_STRUCT.TITLE],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: (data: any) => {
        if (data[QUESTION_STRUCT.TYPE_ID] === QUESTION_TYPES_ID.MATCHING) {
          const res = JSON.parse(data[QUESTION_STRUCT.ELEMENTS])
            .map((element: string, index: number) => {
              return `${element} - ${
                JSON.parse(data[QUESTION_STRUCT.CORRECT_ANSWERS])[index]
              }`;
            })
            .join("\n");
          return res;
        } else {
          return JSON.parse(data[QUESTION_STRUCT.CORRECT_ANSWERS]).join(", ");
        }
      },
      style: { whiteSpace: "pre-line", padding: "10px 0" },
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: (data: any) => {
        const userAnswers = JSON.parse(currentTestResults[TEST_RESULT.ANSWERS]);
        if (data[QUESTION_STRUCT.TYPE_ID] === QUESTION_TYPES_ID.MATCHING) {
          const res = JSON.parse(data[QUESTION_STRUCT.ELEMENTS])
            .map((element: string, index: number) => {
              return `${element} - ${
                userAnswers[data[QUESTION_STRUCT.ID]]
                  ? userAnswers[data[QUESTION_STRUCT.ID]][index]
                  : ""
              }`;
            })
            .join("\n");
          return res;
        } else {
          return userAnswers[data[QUESTION_STRUCT.ID]]
            ? userAnswers[data[QUESTION_STRUCT.ID]].join(", ")
            : "";
        }
      },
      style: { whiteSpace: "pre-line", padding: "10px 0" },
    },
  ];

  const testsHeaderConfig = [
    {
      style: { className: styles.idTitle },
    },
    {},
    {},
  ];

  const rightAnswersData = useMemo(() => {
    const userQuestionsIds = JSON.parse(
      currentTestResults[TEST_RESULT.USER_QUESTIONS]
    ).filter((id: number) =>
      JSON.parse(currentTestResults[TEST_RESULT.RESULT_POINTS])
        ? JSON.parse(currentTestResults[TEST_RESULT.RESULT_POINTS])[id] === 0
        : false
    );

    const userQuestions = questions.filter((question: any) => {
      const userQuestionId = userQuestionsIds.findIndex(
        (questionId: number) => questionId === question[QUESTION_STRUCT.ID]
      );
      if (userQuestionId > -1) {
        return true;
      } else {
        return false;
      }
    });

    const indexMap = new Map();
    userQuestionsIds.forEach((num: number, index: number) =>
      indexMap.set(num, index)
    );

    const sortedObjects = userQuestions.sort(
      (a: any, b: any) =>
        indexMap.get(a[QUESTION_STRUCT.ID]) -
        indexMap.get(b[QUESTION_STRUCT.ID])
    );
    return sortedObjects;
  }, [currentTestResults, questions]);

  return (
    // @ts-ignore
    <Dialog
      title={
        <>
          <div className={styles.dialogTitleContainer}>
            <div className={styles.dialogTitleText}>
              {currentTestResults[TEST_RESULT.TEST]}
            </div>
            {step === STEPS.QUESTIONS && (
              <>
                <div className={styles.dialogQuestionsCountText}>Вопрос</div>
                <div className={styles.dialogQuestionsCountTextColored}>{`${
                  currentQuestionIndex + 1
                } из ${testQuestions.length}`}</div>
              </>
            )}
          </div>
          {!isTestEnded && (
            <div className={styles.row}>
              <div className={styles.timerText}>{"Осталось: "}</div>
              <Timer
                time={
                  currentTestResults[TEST_RESULT.ACTUAL_START]
                    ? actualStartTestTime > 0
                      ? actualStartTestTime
                      : 0
                    : TEST_TIME
                }
              />
            </div>
          )}
        </>
      }
      buttons={buttonsConfig}
      onClick={handleClick}
      bodyClassName={styles.questionContainer}
      fullScreen
    >
      <Step step={STEPS.QUESTIONS} current={stepOrders[step]}>
        <TestDialogQuestion
          currQuestion={currQuestion}
          onQuestionAnswerChange={onQuestionAnswerChange}
          onSelectableQuestionAnswerReset={onSelectableQuestionAnswerReset}
          testResults={testResults}
          isTestEnded={isTestEnded}
        />
      </Step>
      <Step step={STEPS.RESULT} current={stepOrders[step]}>
        <>
          <div className={styles.titleResultContainer}>
            <div
              className={`${styles.titleResultTest} ${
                currentTestResults[TEST_RESULT.TEST_PERCENT] >=
                test[TEST_STRUCT.GOAL_PERCENT]
                  ? styles.succesPercentTextColor
                  : ""
              }`}
            >
              {`${currentTestResults[TEST_RESULT.TEST_PERCENT]}%`}
            </div>
            <div className={`${styles.row} ${styles.subtitleResultTest}`}>
              {`Проходной балл`}
              <div className={styles.subtitleResultTestPercent}>{`${
                test[TEST_STRUCT.GOAL_PERCENT]
              }%`}</div>
            </div>
          </div>
          <div className={`${styles.row} ${styles.summaryResultTestContainer}`}>
            <div className={styles.summaryResultTestTitle}>{`${
              currentTestResults[TEST_RESULT.TEST_PERCENT] >=
              test[TEST_STRUCT.GOAL_PERCENT]
                ? "Тест пройден успешно. Поздравляем! Так держать!"
                : "Тест не пройден, потребуется пересдача. Рекомендуем повторить материалы по препаратам."
            }`}</div>
            <div className={styles.summaryResultTest}>{`${
              currentTestResults[TEST_RESULT.TEST_PERCENT] >=
              test[TEST_STRUCT.GOAL_PERCENT]
                ? `Количество правильных ответов: ${
                    currentTestResults[TEST_RESULT.RESULT_POINTS]
                      ? Object.values(
                          JSON.parse(
                            currentTestResults[TEST_RESULT.RESULT_POINTS]
                          )
                        ).filter((value: any) => value === 1).length
                      : 0
                  } из ${
                    !_.isEmpty(currentTestResults)
                      ? JSON.parse(
                          currentTestResults[TEST_RESULT.USER_QUESTIONS]
                        ).length
                      : 0
                  }.`
                : `Количество правильных ответов: ${
                    currentTestResults[TEST_RESULT.RESULT_POINTS]
                      ? Object.values(
                          JSON.parse(
                            currentTestResults[TEST_RESULT.RESULT_POINTS]
                          )
                        ).filter((value: any) => value === 1).length
                      : 0
                  } из ${
                    !_.isEmpty(currentTestResults)
                      ? JSON.parse(
                          currentTestResults[TEST_RESULT.USER_QUESTIONS]
                        ).length
                      : 0
                  }. Ниже представлены вопросы с неправильными ответами`
            }`}</div>
          </div>
          {rightAnswersData.length > 0 && (
            <Table
              className={styles.rightAnswesTable}
              headers={HEADERS}
              config={tableConfig}
              headerConfig={testsHeaderConfig}
              data={rightAnswersData}
              hideVerticalBorder
            />
          )}
        </>
      </Step>
    </Dialog>
  );
};

export default branch(
  {
    questions: questionsSelector(),
  },
  TestDialog
);
