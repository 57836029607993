import React, { FC, useMemo } from "react";
import { branch } from "baobab-react/higher-order";

import USER_STRUCT from "../../../../store/struct/entities/user";
import VOCABULARY_STRUCT, {
  NA_CONST,
} from "../../../../store/struct/entities/vocabulary";
import {
  agentsSelector,
  directionsSelector,
  linesSelector,
} from "../../../../store/struct/selectors";

import Checkbox from "../../../../components/checkbox";

import styles from "./index.module.scss";

interface TestUsersProps {
  agents: any;
  selectedUsers: number[];
  currentLine: string | null;
  currentDirection: string | null;
  settingsIsDisabled: boolean;
  directions: any;
  lines: any;
  toggleSelectedUser: (userId: number) => void;
  toggleSelectAllUsers: (users: any[]) => void;
}

const TestUsers: FC<TestUsersProps> = ({
  agents,
  selectedUsers,
  currentLine,
  currentDirection,
  settingsIsDisabled,
  directions,
  lines,
  toggleSelectedUser,
  toggleSelectAllUsers,
}) => {
  const isUserSelected = (id: number) => {
    return selectedUsers.findIndex((userId) => userId === id) > -1;
  };

  const filteredUsers = useMemo(() => {
    return agents
      .filter((user: any) => {
        if (currentLine) {
          return (
            user[USER_STRUCT.LINE_ID] === currentLine ||
            user[USER_STRUCT.LINE] === NA_CONST
          );
        } else {
          return true;
        }
      })
      .filter((user: any) => {
        if (currentDirection) {
          return (
            user[USER_STRUCT.DIRECTION_ID] === currentDirection ||
            user[USER_STRUCT.DIRECTION] === NA_CONST
          );
        } else {
          return true;
        }
      });
  }, [agents, currentLine, currentDirection]);

  const currentLineName = currentLine
    ? lines.find(
        (currLine: any) =>
          currLine[VOCABULARY_STRUCT.ID] === Number(currentLine)
      )[VOCABULARY_STRUCT.TITLE]
    : "Линия";
  const currentDirectionName = currentDirection
    ? directions.find(
        (currDirection: any) =>
          currDirection[VOCABULARY_STRUCT.ID] === Number(currentDirection)
      )[VOCABULARY_STRUCT.TITLE]
    : "Направление";

  return (
    <>
      <div
        className={styles.titleText}
      >{`Выберите пользоватей для теста из списка, отфильтрованного на форме "Выбор вопросов"`}</div>
      <div className={styles.filtersContainer}>
        <div className={styles.titleText}>Фильтры:</div>
        <div className={styles.filter}>{currentLineName}</div>
        <div className={styles.filter}>{currentDirectionName}</div>
      </div>
      {filteredUsers.length > 0 ? (
        <>
          <div className={styles.userContainer}>
            <Checkbox
              id={`user-select_all`}
              onChange={() => {
                toggleSelectAllUsers(filteredUsers);
              }}
              checked={selectedUsers.length === filteredUsers.length}
              className={styles.checkbox}
              disabled={settingsIsDisabled}
            />
            <div
              className={`${
                settingsIsDisabled
                  ? styles.userTextContainerDisabled
                  : styles.userTextContainer
              }`}
              onClick={() => {
                if (!settingsIsDisabled) {
                  toggleSelectAllUsers(filteredUsers);
                }
              }}
            >
              {"Выбрать все"}
            </div>
          </div>
          {filteredUsers.map((user: any) => (
            <div
              key={`user-${user[USER_STRUCT.ID]}`}
              className={styles.userContainer}
            >
              <Checkbox
                id={`user-${user[USER_STRUCT.ID]}`}
                onChange={() => {
                  toggleSelectedUser(user[USER_STRUCT.ID]);
                }}
                checked={isUserSelected(user[USER_STRUCT.ID])}
                className={styles.checkbox}
                disabled={settingsIsDisabled}
              />
              <div
                className={`${
                  settingsIsDisabled
                    ? styles.userTextContainerDisabled
                    : styles.userTextContainer
                }`}
                onClick={() => {
                  if (!settingsIsDisabled) {
                    toggleSelectedUser(user[USER_STRUCT.ID]);
                  }
                }}
              >{`${user[USER_STRUCT.NAME]}`}</div>
            </div>
          ))}
        </>
      ) : (
        <div className={styles.emptyText}>
          {"Нет пользователей, соответствующих выбранным фильтрам"}
        </div>
      )}
    </>
  );
};

export default branch(
  {
    agents: agentsSelector(),
    directions: directionsSelector(),
    lines: linesSelector(),
  },
  TestUsers
);
