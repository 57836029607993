import ENTITIES from "..";
import { removeRemote, updateRemote, createRemote } from "../atomic";
import { connection } from "../../..";
import { execAfterUserConfirmation } from "../../app/actions";

export function exportResults(_, data) {
  connection.exportResults(data);
}

export function createQuestion(store, data) {
  execAfterUserConfirmation(store, () => {
    createRemote(store, ENTITIES.QUESTIONS, data);
  });
}

export function removeQuestion(store, data) {
  execAfterUserConfirmation(store, () => {
    removeRemote(store, ENTITIES.QUESTIONS, data);
  });
}

export function editQuestion(store, data) {
  execAfterUserConfirmation(store, () => {
    updateRemote(store, ENTITIES.QUESTIONS, data);
  });
}

export function editUser(store, data) {
  execAfterUserConfirmation(store, () => {
    updateRemote(store, ENTITIES.AGENT, data);
  });
}

export function removeUser(store, data) {
  execAfterUserConfirmation(store, () => {
    removeRemote(store, ENTITIES.AGENT, data);
  });
}

export function exportFile1(store, data) {
  connection.exportFile1(data);
}

export function exportFile2(store, data) {
  connection.exportFile2(data);
}

export function uploadRepsFile(store, file) {
  connection.uploadRepsFile(file);
}

export function uploadQuestionsFile(store, file) {
  connection.uploadQuestionsFile(file);
}

export function sendTestPins(store, testId, users) {
  const siteUrl = document.location.href;
  connection.sendTestPins(testId, siteUrl, users);
}

export function autoLogout(store, exerciseId) {
  connection.autoLogout(exerciseId);
}
