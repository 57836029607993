import React, { FC, createRef, useCallback, useMemo, useState } from "react";
import { branch } from "baobab-react/higher-order";

import {
  editUser,
  removeUser,
  uploadRepsFile,
} from "../../../store/struct/entities/admin/actions";
import {
  agentsSelector,
  directionsSelector,
  linesSelector,
} from "../../../store/struct/selectors";
import USER_STRUCT from "../../../store/struct/entities/user";

import Table, { TYPES as TABLE_TYPES } from "../../../components/table";
import Form, { TYPES as FORM_TYPES } from "../../../components/form";
import Button, { THEMES } from "../../../components/button";
import Dialog from "../../../components/dialog";

import styles from "../index.module.scss";
import Dropzone from "../../../components/dropzone";

const HEADERS = ["ФИО", "ПИН", "Email", "Направление", "Линия", "Менеджер", ""];

interface UsersProps {
  agents: any;
  dispatch: any;
  lines: any;
  directions: any;
}

const Users: FC<UsersProps> = ({ agents, lines, directions, dispatch }) => {
  const [showLoadDialog, setShowLoadDialog] = useState<any>(false);

  const [formData, setFormData] = useState<any>(null);
  const [showEditDialog, setShowEditDialog] = useState<any>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<any>(false);
  const [userToEdit, setUserToEdit] = useState<any>(null);

  const [isNameError, setIsNameError] = useState<any>(false);
  const [isEmailError, setIsEmailError] = useState<any>(false);
  const [isEmailNotUniqueError, setIsEmailNotUniqueError] =
    useState<any>(false);

  const [isManagerNameError, setIsManagerNameError] = useState<any>(false);
  const [isManagerEmailError, setIsManagerEmailError] = useState<any>(false);
  const [isPositionError, setIsPositionError] = useState<any>(false);

  const dropzone = createRef<any>();
  const form = createRef<any>();

  const deleteUser = (data: any) => {
    dispatch(removeUser, {
      [USER_STRUCT.ID]: data[USER_STRUCT.ID],
    });
  };

  const closeDialog = () => {
    setUserToEdit(null);
    setShowEditDialog(false);

    setIsNameError(false);
    setIsEmailError(false);
    setIsEmailNotUniqueError(false);
    setIsManagerNameError(false);
    setIsManagerEmailError(false);
    setIsPositionError(false);
  };

  const openEditUser = (data: any) => {
    setUserToEdit(data);
    setFormData(data);
    setShowEditDialog(true);
  };

  const saveUser = () => {
    const data = {
      id: formData[USER_STRUCT.ID],
      ...form.current.getState(),
    };

    // if (data[QUESTION_STRUCT.TYPE_ID] !== QUESTION_TYPES_ID.MATCHING) {
    //   data[QUESTION_STRUCT.ELEMENTS] = null;
    // }

    dispatch(editUser, data);

    closeDialog();
  };

  const validateInput = (data: any) => {
    return data.length > 0 ? false : true;
  };

  const validateName = useCallback((data: any) => {
    const isInputNotEmpty = validateInput(data);
    setIsNameError(isInputNotEmpty);
  }, []);

  const validateEmail = useCallback(
    (data: any) => {
      const isInputNotEmpty = validateInput(data);
      const isEmailValid = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        data.trim()
      );
      setIsEmailNotUniqueError(false);
      setIsEmailError(isInputNotEmpty || isEmailValid);
      if (!isInputNotEmpty && !isEmailValid) {
        const currEmailIdx = agents
          .filter(
            (agent: any) => agent[USER_STRUCT.ID] !== userToEdit[USER_STRUCT.ID]
          )
          .findIndex(
            (agent: any) =>
              agent[USER_STRUCT.EMAIL].toLowerCase() ===
              data.toLowerCase().trim()
          );

        setIsEmailError(currEmailIdx > -1);
        setIsEmailNotUniqueError(currEmailIdx > -1);
      }
    },
    [agents, userToEdit]
  );

  const validatePosition = useCallback((data: any) => {
    const isInputNotEmpty = validateInput(data);
    setIsPositionError(isInputNotEmpty);
  }, []);

  const validateManagerName = useCallback((data: any) => {
    const isInputNotEmpty = validateInput(data);
    setIsManagerNameError(isInputNotEmpty);
  }, []);

  const validateManagerEmail = useCallback((data: any) => {
    const isInputNotEmpty = validateInput(data);
    const isEmailValid = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      data.trim()
    );
    setIsManagerEmailError(isInputNotEmpty || isEmailValid);
  }, []);

  const formConfig = useMemo(() => {
    const config = [
      {
        id: USER_STRUCT.NAME,
        title: "Имя",
        type: FORM_TYPES.INPUT,
        initialValue: userToEdit ? userToEdit[USER_STRUCT.NAME] : "",

        className: `${styles.userFormInput} ${
          isNameError ? styles.errorInputContainer : ""
        }`,
        classNameLabel: `${styles.marginRight}  ${
          isNameError ? styles.error : ""
        }`,
        classNameLi: styles.rowContainer,
        validationFunc: validateName,
      },
      {
        id: USER_STRUCT.EMAIL,
        title: "Email",
        type: FORM_TYPES.INPUT,
        initialValue: userToEdit ? userToEdit[USER_STRUCT.EMAIL] : "",
        titleUnder: isEmailNotUniqueError
          ? "Введеный email уже используется другим пользователем"
          : "",

        classNameLabelUnder: `${isEmailError ? styles.error : ""}`,
        className: `${styles.userFormInput} ${
          isEmailError ? styles.errorInputContainer : ""
        }`,
        classNameLabel: `${styles.marginRight}  ${
          isEmailError ? styles.error : ""
        }`,
        classNameLi: styles.rowContainer,
        validationFunc: validateEmail,
      },
      {
        id: USER_STRUCT.POSITION,
        title: "Должность",
        type: FORM_TYPES.INPUT,
        initialValue: userToEdit ? userToEdit[USER_STRUCT.POSITION] : "",

        className: `${styles.userFormInput} ${
          isPositionError ? styles.errorInputContainer : ""
        }`,
        classNameLabel: `${styles.marginRight}  ${
          isPositionError ? styles.error : ""
        }`,
        classNameLi: styles.rowContainer,
        validationFunc: validatePosition,
      },

      {
        id: USER_STRUCT.MANADGER_NAME,
        title: "Менеджер",
        type: FORM_TYPES.INPUT,
        initialValue: userToEdit ? userToEdit[USER_STRUCT.MANADGER_NAME] : "",

        className: `${styles.userFormInput} ${
          isManagerNameError ? styles.errorInputContainer : ""
        }`,
        classNameLabel: `${styles.marginRight}  ${
          isManagerNameError ? styles.error : ""
        }`,
        classNameLi: styles.rowContainer,
        validationFunc: validateManagerName,
      },
      {
        id: USER_STRUCT.MANADGER_EMAIL,
        title: "Email менеджера",
        type: FORM_TYPES.INPUT,
        initialValue: userToEdit ? userToEdit[USER_STRUCT.MANADGER_EMAIL] : "",

        className: `${styles.userFormInput} ${
          isManagerEmailError ? styles.errorInputContainer : ""
        }`,
        classNameLabel: `${styles.marginRight}  ${
          isManagerEmailError ? styles.error : ""
        }`,
        classNameLi: styles.rowContainer,
        validationFunc: validateManagerEmail,
      },
      {
        id: USER_STRUCT.LINE_ID,
        title: "Линия:",
        type: FORM_TYPES.SELECT,
        options: lines,
        initialValue: userToEdit ? userToEdit[USER_STRUCT.LINE_ID] : null,
        placeholder: "Выберите линию",
        selectablePlaceholder: true,
      },
      {
        id: USER_STRUCT.DIRECTION_ID,
        title: "Направление:",
        type: FORM_TYPES.SELECT,
        options: directions,
        initialValue: userToEdit ? userToEdit[USER_STRUCT.DIRECTION_ID] : null,
        placeholder: "Выберите направление",
        selectablePlaceholder: true,
      },
    ];
    return config;
  }, [
    directions,
    isEmailError,
    isEmailNotUniqueError,
    isManagerEmailError,
    isManagerNameError,
    isNameError,
    isPositionError,
    lines,
    userToEdit,
    validateEmail,
    validateManagerEmail,
    validateManagerName,
    validateName,
    validatePosition,
  ]);

  const handleChangeForm = (data: any) => {
    setIsSubmitDisabled(
      isNameError ||
        isEmailError ||
        isPositionError ||
        isManagerNameError ||
        isManagerEmailError
    );
  };

  const tableConfig = [
    {
      type: TABLE_TYPES.TEXT,
      className: `${styles.pressableText} ${styles.blueText} ${styles.statusText}`,
      pressable: true,
      onClick: (data: any) => openEditUser(data),
      getValue: (data: any) => data[USER_STRUCT.NAME],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: (data: any) => data[USER_STRUCT.PIN],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: (data: any) => data[USER_STRUCT.EMAIL],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: (data: any) => data[USER_STRUCT.DIRECTION],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: (data: any) => data[USER_STRUCT.LINE],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: (data: any) => data[USER_STRUCT.MANADGER_NAME],
    },
    {
      type: TABLE_TYPES.BUTTON,
      theme: THEMES.DELETE,
      onClick: deleteUser,
      text: "Удалить",
      min: true,
      getProps: (data: any) => {
        return {
          disabled: !data[USER_STRUCT.IS_DELETE_AVAILABLE],
        };
      },
    },
  ];

  const loadUsers = () => {
    setShowLoadDialog(true);
  };

  const onLoadReps = () => {
    const file = dropzone.current.getFile();

    if (file) {
      dispatch(uploadRepsFile, file);
      closeLoadDialog();
    }
  };
  const closeLoadDialog = () => {
    setShowLoadDialog(false);
  };

  const testsHeaderConfig = [
    {},
    {},
    {},
    {
      style: { className: styles.selectorHeader },
    },
    {
      style: { className: styles.selectorHeader },
    },
    {
      style: { className: styles.selectorHeader },
    },
    {},
  ];

  return (
    <>
      {agents.length > 0 ? (
        <Table
          className={styles.tableWithControl}
          headers={HEADERS}
          config={tableConfig}
          data={agents}
          headerConfig={testsHeaderConfig}
          filters={[3, 4, 5]}
        />
      ) : (
        <div className={styles.emptyListTitle}>
          Список пользователей пуст. Загрузите файл
        </div>
      )}
      <div
        className={`${styles.alignRight} ${styles.block} ${styles.blockAlingRight}`}
      >
        {/* @ts-ignore */}
        <Button onClick={loadUsers} theme={THEMES.NEW_PRIMARY}>
          Добавить
        </Button>
      </div>
      {showLoadDialog && (
        // @ts-ignore
        <Dialog
          title="Загрузка файла"
          submitTitle="Сохранить"
          onSubmit={onLoadReps}
          cancelTitle="Отменить"
          onCancel={closeLoadDialog}
          fullScreen
        >
          <Dropzone ref={dropzone} />
        </Dialog>
      )}
      {showEditDialog && (
        // @ts-ignore
        <Dialog
          title={"Редактирование пользователя"}
          submitTitle="Сохранить"
          onSubmit={saveUser}
          submitDisabled={isSubmitDisabled}
          cancelTitle="Отменить"
          onCancel={closeDialog}
          fullScreen
        >
          <Form
            ref={form}
            // @ts-ignore
            config={formConfig}
            onChange={handleChangeForm}
          />
        </Dialog>
      )}
    </>
  );
};

export default branch(
  {
    agents: agentsSelector(),
    lines: linesSelector(),
    directions: directionsSelector(),
  },
  Users
);
