import React, { FC, useState } from "react";
import { branch } from "baobab-react/higher-order";

import {
  testsSelector,
  questionsSelector,
  agentsSelector,
} from "../../../store/struct/selectors";
import TEST_STRUCT from "../../../store/struct/entities/test";
import { exportResults } from "../../../store/struct/entities/admin/actions";

import Button, { THEMES } from "../../../components/button";
import Table, { TYPES as TABLE_TYPES } from "../../../components/table";
import CreateTestDialog from "./createTestDialog";

import styles from "./index.module.scss";
import { getUTCDate } from "../../../utils/time";

const HEADERS = ["№", "Тест/Настройки", "Начало", "Окончание", "Статус"];

interface TestsProps {
  tests: any;
  questions: number[];
  agents: number[];
  dispatch: any;
}

const Tests: FC<TestsProps> = ({ tests, questions, agents, dispatch }) => {
  const [showCreateDialog, setShowCreateDialog] = useState<any>(false);
  const [testToChangeSettings, setTestToChangeSettings] = useState<any>(null);

  const openTestSettings = (data: any) => {
    setShowCreateDialog(true);
    setTestToChangeSettings(data);
  };

  const handleExportResults = (data: any) => {
    dispatch(exportResults, data[TEST_STRUCT.ID]);
  };

  const tableConfig = [
    {
      type: TABLE_TYPES.TEXT,
      getValue: (_: any, index: number) => index + 1,
    },
    {
      type: TABLE_TYPES.TEXT,
      className: `${styles.pressableText} ${styles.blueText} ${styles.statusText}`,
      pressable: true,
      onClick: (data: any) => openTestSettings(data),
      getValue: (data: any) => data[TEST_STRUCT.TITLE],
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: (data: any) => {
        const date = getUTCDate(data[TEST_STRUCT.START_DATE]);
        return `${
          date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
        }.${
          date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
        }.${date.getFullYear()} ${
          date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
        }:${
          date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
        }`;
      },
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: (data: any) => {
        const date = getUTCDate(data[TEST_STRUCT.END_DATE]);
        return `${
          date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
        }.${
          date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
        }.${date.getFullYear()} ${
          date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
        }:${
          date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
        }`;
      },
    },
    {
      type: TABLE_TYPES.CONDITIONAL,
      getComponentIndex: (data: any) => {
        const now = getUTCDate(Date.now());
        const startDate = getUTCDate(data[TEST_STRUCT.START_DATE]);
        const endDate = getUTCDate(data[TEST_STRUCT.END_DATE]);

        if (startDate < now && now > endDate) {
          return 1;
        } else if (startDate < now && now < endDate) {
          return 2;
        } else {
          return 0;
        }
      },
      components: [
        {
          type: TABLE_TYPES.TEXT,
          getValue: () => "Не начат",
          className: `${styles.noMargin} ${styles.centerText} ${styles.statusTextLight}`,
        },
        {
          type: TABLE_TYPES.TEXT,
          getValue: () => "Ответы",
          pressable: true,
          onClick: (data: any) => handleExportResults(data),
          className: `${styles.noMargin} ${styles.centerText} ${styles.pressableText} ${styles.blueText} ${styles.statusText}`,
        },
        {
          type: TABLE_TYPES.TEXT,
          getValue: () => "В процессе",
          className: `${styles.noMargin} ${styles.centerText} ${styles.statusText}`,
        },
      ],
    },
  ];

  const createTest = () => {
    if (agents.length < 1) {
      alert(
        "Список пользователей пуст. Для создания теста необходимо сначала загрузить пользователей"
      );
    } else if (questions.length < 1) {
      alert(
        "Список вопросов пуст. Для создания теста необходимо сначала загрузить вопросы"
      );
    } else {
      setShowCreateDialog(true);
    }
  };

  const closeCreateDialog = () => {
    setShowCreateDialog(false);
    setTestToChangeSettings(null);
  };

  const testsHeaderConfig = [
    {},
    {
      style: { className: styles.wideTitle },
    },
    {},
    {},
    {
      style: { className: styles.centerText },
    },
  ];

  return (
    <>
      {tests.length > 0 ? (
        <Table
          className={styles.tableWithControl}
          headers={HEADERS}
          config={tableConfig}
          headerConfig={testsHeaderConfig}
          data={tests}
        />
      ) : (
        <div className={styles.emptyListTitle}>Список тестов пуст</div>
      )}
      <div
        className={`${styles.alignRight} ${styles.block} ${styles.blockAlingRight}`}
      >
        {/* @ts-ignore */}
        <Button onClick={createTest} theme={THEMES.NEW_PRIMARY}>
          Создать тест
        </Button>
      </div>
      {showCreateDialog && (
        // @ts-ignore
        <CreateTestDialog
          closeCreateDialog={closeCreateDialog}
          testToChangeSettings={testToChangeSettings}
        />
      )}
    </>
  );
};

export default branch(
  {
    tests: testsSelector(),
    questions: questionsSelector(),
    agents: agentsSelector(),
  },
  Tests
);
