import STRUCT from "../";

export function set(store, data) {
  store.select(STRUCT.ENTITIES).set(data);
}

export function update(store, entity, data) {
  if (store.exists([STRUCT.ENTITIES, entity])) {
    if (Array.isArray(data)) {
      store.select([STRUCT.ENTITIES, entity]).set(data);
    } else if (store.exists([STRUCT.ENTITIES, entity, { id: data.id }])) {
      store.select([STRUCT.ENTITIES, entity, { id: data.id }]).merge(data);
    } else {
      create(store, entity, data);
    }
  }
}

export function create(store, entity, data) {
  store.select([STRUCT.ENTITIES, entity]).push(data);
}

export function remove(store, entity, data) {
  const cursor = store.select([STRUCT.ENTITIES, entity]);

  cursor.set(cursor.get().filter(({ id }) => id !== data.id));
}
